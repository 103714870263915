import React from "react";
import DatePicker from "react-datepicker";
import {Box, Button, Container, Flex, Txt} from "rendition";
import Icon from "../../lib/icon";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { utcOnChange } from "../../lib/common";

/**
 * Component to make text-icon combo as datepicker.
 * 
 * @param {string | undefined} value - date value available from/to datepicker.
 * @param {function} onChange - update function when value changes
 *      (for parent to know).
 * @param {boolean} disabled - flag to mark datepicker as disabled.
 * @returns {React.JSX}
 */
export const DateSelection = ({ value, onChange, disabled, onClick, ...props }) =>
    <DatePicker
        showWeekNumbers
        locale='nb'
        dateFormat='dd.MM.yyyy'
        placeholderText='d.m.yyyy'
        disabled={disabled}
        selected={value ? new Date(value) : undefined}
        onChange={utcOnChange(onChange)}
        customInput={
        <Flex
            onClick={onClick}
            style={{
                alignItems: 'center',
                cursor: disabled ? 'not-allowed' : 'pointer',
                width: '100%'
            }}
        >
            <Txt style={{width: '85px'}} onClick={onClick}>
                {value ? moment(value).format('DD.MM.YYYY') : 'dd.mm.åååå'}
            </Txt>
            <Icon name='date' f='right' pt='5px'/>
        </Flex>}
        {...props}
    >
        <Box style={{ margin: '2em' }}>
            <Button plain onClick={() => onChange("")}>
                fjern
            </Button>
        </Box>
    </DatePicker>

/**
 * Component to make text-text(date)-icon combo as datepicker input.
 * 
 * @param {string | undefined} value - date value available from/to datepicker.
 * @param {function} onChange - update function when value changes
 *      (for parent to know).
 * @param {function} onClick - click event handling - on the whole block.
 * @param {boolean} disabled - flag to mark datepicker as disabled.
 * @param {string} text - first text value.
 * @param {string} width - width - in string of the component.
 * @returns {React.JSX}
 */
export const DateSelectionInput = ({
    value,
    onChange,
    onClick,
    text,
    disabled,
    width,
    ...props
}) =>
    <DatePicker
        showWeekNumbers
        locale='nb'
        dateFormat='dd.MM.yyyy'
        placeholderText='d.m.yyyy'
        disabled={disabled}
        selected={value ? new Date(value) : undefined}
        onChange={utcOnChange(onChange)}
        customInput={
        <Flex
            onClick={onClick}
            style={{
                alignItems: 'center',
                cursor: 'pointer',
                width: {width},
                justifyContent: 'space-between',
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "10px 10px 5px 10px",
            }}
        >
            <Txt>{text}</Txt>
            <Txt>
                {value ? moment(value).format('DD.MM.YYYY') : 'dd.mm.åååå'}
            </Txt>
            <Icon name='date' f='right'/>
        </Flex>}
        {...props}
    >
        <Box style={{ margin: '2em' }}>
            <Button plain onClick={() => onChange("")}>
                fjern
            </Button>
        </Box>
    </DatePicker>


/**
 * Extension of DateSelection to include cancel button to remove the value.
 * 
 * @param {string | undefined} value - date value available from/to datepicker.
 * @param {function} onChange - function called when value changes.
 * @param {function} onClose - function called when value is cleared out.
 * @param {string} backgroundCol - hex string representation of the box
 *      background color.
 * @returns {React.JSX}
 */
export const DateSelectionWithCancel = ({ value, onChange, backgroundCol, onClose, ...props }) =>
    <Container style={{
        border: value ? "1px solid #ccc" : "1px solid red",
        borderRadius: "20px",
        margin: "0",
        fontSize: "14px",
        height: "38px",
        width: "160px",
        ...backgroundCol ? { backgroundColor: backgroundCol } : {}
    }}>
        <Flex style={{ "padding": "6px 0px 5px 0px" }}>
            <DateSelection value={value} onChange={onChange} {...props} />
            <Button plain onClick={onClose} style={{ "marginLeft": "1em" }}>
                <FontAwesomeIcon icon={faTimes} />
            </Button>
        </Flex>
    </Container>