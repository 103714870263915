import React from "react";
import Icon from "../lib/icon";
import { Popover } from 'react-tiny-popover';

const ErrorMessagePopover = (error, errorType, errorMessage) => {
  return error?.type === errorType && <Popover
    isOpen={true}
    positions={['bottom']}
    align="center"
    reposition={false}
    content={<div style={{
      width: "200px",
      backgroundColor: "#FFF",
      borderRadius: "10px",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.12)",
      padding: "1px 5px 5px",
      fontSize: "14px",
      fontFamily: "Roboto"
    }}><span style={{
      whiteSpace: "pre-line", color: "#BF3D2B"
    }}><Icon m="2px 3px 0 0" name="error" />{errorMessage}</span></div>}
  >
    <span></span>
  </Popover>
}
export default ErrorMessagePopover;
