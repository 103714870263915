
const defaultWeeklyCoverDemands = {
  "MONDAY": {},
  "TUESDAY": {},
  "WEDNESDAY": {},
  "THURSDAY": {},
  "FRIDAY": {},
  "SATURDAY": {},
  "SUNDAY": {},
  "HOLIDAYS": {}
};

const defaultConfigObj = {
  "status": "PREPARING",
  "config": {
    "nrOfWeeks": 12,
    "weeklyCoverDemands": defaultWeeklyCoverDemands,
    "freeMinBetween": 8.0,
    "shiftMaxLoadRollingPeriod": 54
  }
};

const defaultDepartment = {
  "name": "",
  "config": {
    "maxConsecutiveShifts": "5"
  }
}

export { defaultConfigObj, defaultDepartment, defaultWeeklyCoverDemands};
