import React, { useState } from "react";
import Select from "react-select";

const createOption = (label) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ''),
});

const MultiSelectPicker = ({ defaultValue, defaultOptions, onChange, placeholderPostfix, disabled=false }) => {
  const [value, setValue] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(false);
  const selectAllFromDropdown = {id: "constant-id-7ade1d8a-b2a8-11ed-afa1-0242ac120002", value: "Velg alle", label: "Velg alle"};
  const [options, setOptions] = useState(defaultOptions);

  const handleCreate = (inputValue) => {
    setIsLoading(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoading(false);
      setOptions([...options, newOption]);
      setValue([...value, newOption]);
    }, 1000);
  };

  const allSelected = () => {
      return options?.sort().join(",") === value?.sort().join(",");
  }

  return (
    <Select
      isClearable
      isMulti
      closeMenuOnSelect={false}
      isDisabled={isLoading || disabled}
      isLoading={isLoading}
      onChange={(e) => {
          let selectAll = e.filter(v => v.id === selectAllFromDropdown.id).length > 0;
          let clearAll = e.length === 0;

          let newValues = selectAll ? defaultOptions : (clearAll ? [] : e);
          setValue(newValues);
          onChange(newValues);
      }}
      onCreateOption={handleCreate}
      options={allSelected() ? [] : [selectAllFromDropdown].concat(options)}
      value={value}
      defaultValue={defaultValue}
      placeholder={`Velg ${placeholderPostfix}...`}
      noOptionsMessage={({ inputValue }) => options.length === 0 ? `Ingen ${placeholderPostfix} funnet` : !inputValue ? `Alle ${placeholderPostfix} valgt` : ""}
      formatCreateLabel={(inputValue) => `Opprett ${inputValue}`}
    />
  );
};

export { MultiSelectPicker };
