import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Icon from "../lib/icon";
import Tooltip from "../lib/tooltip";

const PublicNav = () => {

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>

      <StyledIcon>
        <Icon m="25px" name="dynamon_logo" />
      </StyledIcon>

      <StyledNavLink
        to={`/`}
        exact
        className="nav-link"
        activeClassName="router-link-exact-active"
        data-for="departments-tooltip"
        data-tip="Ved å klikke på “Avdelinger” vil du alltid kunne gå ut til oversikten over avdelinger"
      >
        <Tooltip id="departments-tooltip" />
        <MenuIconContainer>
          <MenuIcon name="dashboard" />
        </MenuIconContainer>
        Avdelinger
      </StyledNavLink>
    </div>
  )
};

const MenuIcon = styled(Icon)`
  margin-top: 15px;
  margin-left: 15px;
`;

const MenuIconContainer = styled.div`
  background: #398089;
  width: 64px;
  height: 64px;
  border-radius: 100px;
  margin-bottom: 10px;
  margin-right: auto;
  margin-left: auto;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  color: white;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 15px;
  padding-top: 15px;
  text-align: center;
  width: 105px;
  &:hover {
    background-color: #4FA7B0;
  }
`;

const StyledIcon = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 15px;
  padding-top: 15px;
  text-align: center;
  width: 105px;
`;

export default PublicNav;
