import React, { useEffect, useState } from "react";
import { dynoapeAPI } from "../api/dynoape";
import { useParams, NavLink, useHistory } from "react-router-dom";
import { EmployeeTable, Header } from "../components";
import styled from 'styled-components';
import Icon from "../lib/icon";
import {Card, CardRow, SolidInput} from "../lib/styled-components";
import { Button, Modal } from "rendition";
import Tooltip from "../lib/tooltip";
import { validCharactersRegex } from "../lib/common";

const Department = () => {
  const [showDepartmentModal, setShowModal] = useState(false);
  const [showInvalidNameError, setShowInvalidNameError] = useState({ "show": false, "msg": "" });
  const [isLoading, setIsLoading] = useState(true);
  const [department, setDepartment] = useState({});
  const [countryRules, setCountryRules] = useState({});
  const [departmentName, setNameInput] = useState("");
  const { departmentId } = useParams();
  const history = useHistory();

  const closeEditNameModal = () => {
    setShowInvalidNameError({ "show": false, "msg": "" });
    setNameInput("");
    setShowModal(false);
  }

  const updateDepartment = async () => {
    if (departmentName.trim().length <= 0) {
      setShowInvalidNameError({ "show": true, "msg": "Avdelingsnavn er påkrevd" });
      return;
    }
    if (showInvalidNameError.show) {
      return;
    }

    let cr = department.countryRules ? department.countryRules : countryRules.filter(c => c.countryCode === "NO")[0];
    let req = {name: departmentName.trim(), "countryRulesId": cr.id, config: department.config};

    const response = await dynoapeAPI.put(`/api/v1/department/${departmentId}`, req);

    setDepartment(response);
    closeEditNameModal();
  };

  const getDepartment = async () => {
    const [dep, cr] = await Promise.all([
      dynoapeAPI.get(`/api/v1/department/${departmentId}`),
      dynoapeAPI.get(`/api/v1/countryrules`)
    ]);
    setDepartment(dep);
    setCountryRules(cr)
    setIsLoading(false);
  };

  useEffect(() => {
    getDepartment();
  }, []);

  if (isLoading || department === undefined) {
    return (<div>
      <p>Loading...</p>
      {!isLoading && department === undefined && (
        <Modal
          title="Avdeling"
          cancel={() => {

          }}
          cancelButtonProps={{
            style: { display: "none" }
          }}
          done={() => {
            history.push(`/avdelinger`);
          }}
          action="Gå tilbake"
        >
          <p>Denne avdelingen er ikke delt med deg.</p> <p>Ta kontakt med eier av avdelingen eller <a href="mailto: support@dynamon.no">support@dynamon.no</a></p>
        </Modal>
      )}
    </div>
    )
  }

  return (
    <div>
      <Header title={"Dashboard"} subTitle={department.name}
        withTooltip
        tooltipId="dep-header"
        tooltipContent="Her finner du oversikt over alle funksjoner i Dynamon">
        <Button
          primary
          ml="auto"
          onClick={() => {
            setNameInput(department.name);
            setShowModal(true)
          }}
        >
          <Icon name="edit-w" />Rediger avdelingsnavn
        </Button>
      </Header>
      <div className="content" style={{ marginTop: "-40px" }}>
        <CardRow>
          <NavCard className="nav-link" to={`${department.id}/ansatte`}
            data-for="employees-card-tooltip"
            data-tip="Her finner du oversikt over alle ansatte i din avdeling og kan legge inn eller slette ansatte, samt spesifisere hvordan de skal arbeide"
          >
            <Tooltip id="employees-card-tooltip" />
            <Icon name="user-2-filled" />
            <h3>Ansatte</h3>
          </NavCard>
          <NavCard className="nav-link" to={`${department.id}/bemanningsplaner`}
            data-for="tasks-card-tooltip"
            data-tip="Her finner du de bemanningsplaner du har laget, eller oppretter ny bemanningsplan for din avdeling"
          >
            <Tooltip id="tasks-card-tooltip" />
            <Icon name="list-filled" />
            <h3>Bemanning</h3>
          </NavCard>
        </CardRow>

        <CardRow>
          <Card style={{ width: "1360px", minHeight: "500px", padding: "40px", overflow: "hidden", margin: "15px 0" }}>
            <EmployeeTable />
          </Card>
        </CardRow>
      </div>
      {showDepartmentModal && (
        <Modal
          title="Avdeling"
          cancel={() => {
            closeEditNameModal();
          }}
          cancelButtonProps={{
            children: 'Avbryt'
          }}
          done={() => {
            updateDepartment();
          }}
          action="Lagre"
        >
          <SolidInput
            onChange={(e) => {
              let name = e.target.value.trim();
              setNameInput(name);

              if (name.length <= 0) {
                setShowInvalidNameError({ "show": true, "msg": "Avdelingsnavn er påkrevd" });
                return;
              }

              if (name.length > 40) {
                setShowInvalidNameError({ "show": true, "msg": "Navn på avdeling kan ikke være lenger enn 40 tegn" });
                return;
              }

              if (!validCharactersRegex().test(name)) {
                setShowInvalidNameError({ "show": true, "msg": "Navn på avdeling kan ikke inneholde ugyldige tegn" });
                return;
              }

              setShowInvalidNameError({ "show": false, "msg": "" });
            }}
            placeholder="Avdelingsnavn"
            defaultValue={department.name}
          />
          {showInvalidNameError && (
            <p style={{ color: "#BF3D2B" }}>{showInvalidNameError.msg}</p>
          )}
        </Modal>
      )}
    </div>
  )
};

const NavCard = styled(NavLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 180px;
  width: 710px;
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  margin: 0 10px;
  &:hover {
    background-color: #E6FFF8;;
  }
`;

export default Department;
