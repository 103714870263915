
export class PatternCase {
    static WEEKEND_PATTERN = "weekendPatterns";
    static BLOCKED_PATTERN = "blockedPatterns";
    static VACATION = "vacationPatterns";
    static HOLIDAY_PATTERN = "holidayPatterns";
    static PAID_LEAVE_PATTERN = "paidLeavePatterns"

    static translate(patternCase) {
        switch (patternCase) {
            case this.WEEKEND_PATTERN: return "Helgeplanlegger";
            case this.BLOCKED_PATTERN: return "Annet fravær";
            case this.VACATION: return "Ferieplanlegger";
            case this.HOLIDAY_PATTERN: return "Helligdagsplanlegger";
            case this.PAID_LEAVE_PATTERN: return "Permisjonsplanlegger"
            default: return "Fravær";
        }
    }

    static getIncludeQueryParams(patternCase) {
        const all = "?includeWeekendPatterns=true&includeBlockedPatterns=true" +
            "&includeVacationPatterns=true" +
            "&includeHolidayPatterns=true" +
            "&includePaidLeavePatterns=true";
        switch (patternCase) {
            case this.BLOCKED_PATTERN: return all;
            case this.WEEKEND_PATTERN: return all;
            case this.VACATION: return all;
            case this.HOLIDAY_PATTERN: return all;
            case this.PAID_LEAVE_PATTERN: return all;
            default: return "";
        }
    }
}