import React, { useEffect, useState} from "react";
import {dynoapeAPI} from "../../api/dynoape";
import {
    dynomiteErrorPanel,
    getDaysFromDatePattern, getDaysFromDatePatterns,
    getWeekdaysFromTask,
    monthsShort,
    scrollHeight, shortenStr,
    singleDaysDatePattern, sortShiftTypesByDaySegment
} from "../../lib/common";
import {ReactGrid} from "@silevis/reactgrid";
import "../grid-components/grid-styles.css";
import {DropdownCellTemplate} from "../grid-components/dropdown-cell-template";
import {CheckboxCellTemplate} from "../grid-components/checkbox-cell-template";
import {sundaysMaxSeqOptions, weekendFreqOptions, weekendShiftOptions} from "../../lib/options";
import moment from "moment";
import {Button, Flex} from "rendition";
import Tooltip from "../../lib/tooltip";
import {WeekJumper} from "../../lib/styled-components";
import {DropdownSelectCellTemplate} from "../grid-components/dropdown-select-cell-template";
import {PlannerWrapper, ReactGridWrapper} from "./employee-date-pattern-cases";
import {DisabledInfoCellTemplate} from "../grid-components/disabled-info-cell-template";
import {PatternCase} from "../../lib/pattern-case";

export default ({
                    somePatternCaseIsDirty, setSomePatternCaseIsDirty, viewStartIndex, setViewStartIndex, setViewFlipSize, viewFlipSizeRef,
                    taskSelect, searchBar, routerPrompt,
                    departmentId,
                    setSelectedTaskOption, selectedTask, setSelectedTask,
                    department, holidays, processAndSetHolidays, employees, setEmployees,
                    patternCase,
                    reset,
                    getReactGridRows, getReactGridHeaderRow,
                    showSavingModal, setShowSavingModal, saveModal,
                    dynomite, validate, configErrors, configWarnings, dynomiteInternalError,
                    startDate, setStartDate, nrOfWeeks, setNrOfWeeks, getRequirementsFromTask,
                    openedDropdownLocation, findAndSetOpenedDropdownCellLocation,
                    searchFilters, applySearchFiltersToEmployees, getPatternsForDaysAndEmployees}) => {

    const [toSave, setToSave] = useState([]);
    const nrInView = 12;

    const [columns, setColumns] = useState(undefined);
    const [sundays, setSundays] = useState(undefined);
    const [sundayRequirements, setSundayRequirements] = useState(undefined);
    const [vacationsByEmployeeAndSunday, setVacationsByEmployeeAndSunday] = useState({});
    const [paidLeaveByEmployeeAndSunday, setPaidLeaveByEmployeeAndSunday] = useState({});

    useEffect(() => {
        initialize(startDate, nrOfWeeks);
        return () => {
            reset();
        }
    }, [])

    useEffect(() => {
        if(!sundays) return;
        if(!selectedTask) {
            setVacationsByEmployeeAndSunday(getPatternsForDaysAndEmployees(sundays, PatternCase.VACATION));
            setPaidLeaveByEmployeeAndSunday(getPatternsForDaysAndEmployees(sundays, PatternCase.PAID_LEAVE_PATTERN));
        }
    }, [sundays])

    useEffect(() => {
        if((somePatternCaseIsDirty && toSave.length > 0) || (!somePatternCaseIsDirty && toSave.length === 0)) return;
        setSomePatternCaseIsDirty(toSave.length > 0);
    }, [toSave])

    useEffect(() => {
        validate({employeesParam: applySearchFiltersToEmployees(employees).map(e => getLocalOrGlobalEmployee(e.id))});
    }, [toSave, employees, searchFilters])

    /**
     * Initialize the page:
     * - Set sundays to all sundays current year
     * @returns {Promise<void>}
     */
    const initialize = async (start=startDate, weeks=nrOfWeeks) => {
        processAndSetHolidays(department, start, weeks);
        const sun = getWeekdaysFromTask({config: {startDate: moment(start), nrOfWeeks: weeks}}, [0]).slice(0, nrInView);
        setSundays(sun);
        scrollHeight(1500)
    }

    /**
     * Helper function for getting employee data. If there are unsaved employee data, we fetch it from local storage.
     * If not, the employee is returned
     * @param employeeId
     * @returns {*}
     */
    const getLocalOrGlobalEmployee = (employeeId) => {
        return toSave.find(e => e.id === employeeId) || employees.find(e => e.id === employeeId);
    }

    /**
     * Function for creating a new valid weekend pattern based on either empty initial pattern or existing one
     * @param employeeId
     * @param pattern
     * @returns {{summary: (*|string), includeDays: (*|*[]), optimize: boolean, series: {nth: (number|number), toggled: boolean, from: string, until: *, mask: boolean[]}, kind: string, daySegments: string[], disabled: boolean, id: *, excludeDays: *[]}}
     */
    const validWeekendPattern = (employeeId, pattern) => {
        const nth = isNaN(Number(pattern?.series?.nth)) ? 0 : Number(pattern?.series?.nth);
        return {
            id: pattern?.id,
            summary: pattern?.summary || "Skal jobbe",
            series: {
                toggled: nth > 0,
                from: pattern?.series?.from ? moment(pattern?.series?.from).format('YYYY-MM-DD') : moment(startDate).startOf('isoWeek').format('YYYY-MM-DD'),
                until: pattern?.series?.until,
                nth: nth,
                mask: [false, false, false, false, false, false, true]
            },
            includeDays: pattern?.includeDays || [],
            excludeDays: [],
            daySegments: ["D", "A", "N", "L"],
            disabled: false,
            optimize: false,
            kind: "working"
        }
    }

    /**
     * UseEffect for generating the columns in the weekend planner. The following columns are generated:
     * - Row nr
     * - Employee name
     * - Weekend frequency of the employee
     * - Weekend frequency start week of the employee
     * - Employee sundayMaxSequence
     * - Employee worksOnlyWeekend
     * - Employee weekendShiftMaxSequence
     * - Number of work weekends
     * - One column for each sunday in the either current year or a selected BP
     *
     * This useEffect is triggered every time there is a change in the employee list, employee local storage,
     * sundays or openedDropdownLocation (opening of select box containing dates for selecting start week)
     */
    useEffect(() => {
        if(!sundays || !department) return;
        const _employees = applySearchFiltersToEmployees(employees, sundays[0], sundays[sundays.length -1]);
        const cols =
            [
                { columnId: "nr", included: true, width: 40, text: "Rad", item: ({ idx }) => ({ type: "header", text: `${idx + 1}` }), demand: "", cover: "", diff: "", turnusWeek: "" },
                { columnId: "name", included: true, width: 265, text: "Navn", item: ({ employee }) => ({ type: "header", text: `${shortenStr(employee.name, 30)}` }), demand: "Bem.krav", cover: "Dekning", diff: "Differanse", turnusWeek: ""},
                { columnId: "position", included: true, width: 150, text: "Stilling", item: ({ employee }) => ({ type: "header", text: shortenStr(`${employee.restPosition?.name}`) }), demand: "", cover: "", diff: "", turnusWeek: ""},
                { columnId: "daySegment", included: true, width: 150, text: "Vaktkategorier", item: ({ employee }) => ({ type: "header", text: `${[...new Set(sortShiftTypesByDaySegment(employee.restShiftTypes)?.map(sh => sh.daySegment))]?.join(', ')}` }), demand: "", cover: "", diff: "", turnusWeek: ""},
                { columnId: "weekendFrequency", included: true, width: 80, text: "Helge \nfrekvens", item: ({ employee }) => ({ type: "dropdown-number", text: resolveWeekendFrequency(employee.id), values: weekendFreqOptions }), demand: "", cover: "", diff: "", turnusWeek: "" },
                { columnId: "weekendFrequencyStart", included: true, width: 160, text: "Første \narbeidshelg", item: ({ employee }) => (
                    resolveWeekendFrequency(employee.id) === 0 ? {type: 'empty', text: '', nonEditable: true} :
                    {
                        type: "dropdown",
                        isOpen: openedDropdownLocation?.columnId === "weekendFrequencyStart" && openedDropdownLocation?.rowId === employee.id ,
                        selectedValue:  [resolveWeekendFrequencyStartWeek(employee.id)].map(sun => moment(sun)).map(sun =>`${sun.year()} Uke ${sun.month() === 11 && sun.isoWeek() === 1 ? 53 : sun.isoWeek()}`)[0],
                        values: [...new Set([resolveWeekendFrequencyStartWeek(employee.id)].concat(sundays.map(sun => sun.format('YYYY-MM-DD'))))].map(sun => moment(sun)).map((sun) => ({ label: `${sun.year()} Uke ${sun.month() === 11 && sun.isoWeek() === 1 ? 53 : sun.isoWeek()}`, value: moment(sun).format('YYYY-MM-DD')})).filter(sun => sun)
                    }), demand: "", cover: "", diff: "", turnusWeek: "" },
                { columnId: "sundaysMaxSequence", included: true, width: 170, text: "Maks. antall søndager på rad", item: ({ employee }) => ({ type: "dropdown-number", text: getLocalOrGlobalEmployee(employee.id).sundaysMaxSequence, values: sundaysMaxSeqOptions }), footer: "", demand: "", cover: "", diff: "", turnusWeek: "" },
                { columnId: "worksOnlyWeekend", included: true, width: 80, text: "Jobber kun helg", item: ({ employee }) => ({ type: "checkbox", checked: !!getLocalOrGlobalEmployee(employee.id).worksOnlyWeekend, checkedText: "Ja",
                        uncheckedText: "Nei" }), footer: "", demand: "", cover: "", diff: "", turnusWeek: "" },
                { columnId: "weekendShiftMaxSequence", included: true, width: 150, text: "Maks. antall vakter på rad rundt helg", item: ({ employee }) => ({ type: "dropdown-number",
                        text: [getLocalOrGlobalEmployee(employee.id)].map(e => e.weekendShiftMaxSequence && e.weekendShiftMaxSequence > -1 ? e.weekendShiftMaxSequence : "-")[0] ,
                        values: weekendShiftOptions(department?.config?.maxConsecutiveShifts || 5) }), footer: "", demand: "", cover: "", diff: "", turnusWeek: "" },
                { columnId: "nrOfWeekends", included: true, width: 80, text: "Antall arb.helger", item: ({ employee }) => ({ type: "header", text: `${selectedTask ? resolveNumberOfWorkingWeekends(employee.id) : ""}` }), demand: "", cover: "", diff: "", turnusWeek: "" },
                { columnId: "empty", included: true, width: 1, text: "", item: () => ({ type: "header", text: '' }), demand: "", cover: "", diff: "", turnusWeek: "" }
            ]
                .concat(sundays.map(d => {
                    const isOnVacation = (employeeId) => {
                        return vacationsByEmployeeAndSunday[moment(d).format('YYYY-MM-DD')] !== undefined
                            && vacationsByEmployeeAndSunday[moment(d).format('YYYY-MM-DD')][employeeId] === true
                    }
                    const isOnPaidLeave = (employeeId) => {
                        return paidLeaveByEmployeeAndSunday[moment(d).format('YYYY-MM-DD')] !== undefined
                            && paidLeaveByEmployeeAndSunday[moment(d).format('YYYY-MM-DD')][employeeId] === true
                    }
                    const id = d.format("YYYY-MM-DD");
                    const daySegmentFilters = searchFilters.daySegments === undefined || searchFilters.daySegments.length === 0
                        ? ["D", "A", "L", "N"]
                        : searchFilters.daySegments;

                    const _sunRequirements = sundayRequirements !== undefined && sundayRequirements[id] !== undefined
                        ? daySegmentFilters.reduce((acc, ds) => {
                            acc.min += sundayRequirements[id][ds].min;
                            acc.max += sundayRequirements[id][ds].max;
                            return acc;
                        }, {min: 0, max: 0})
                        : undefined;

                    const text = `Uke ${d.isoWeek()} \n ${d.year()} ${monthsShort[d.month()]} `
                    const demand = _sunRequirements !== undefined ? _sunRequirements.min + "" : "";
                    const maxDemand = _sunRequirements !== undefined ? _sunRequirements.max + "" : "";
                    const cover = _employees.reduce((count, e) => {return count + (worksWeekend(e.id, d) ? 1 : 0);}, 0) +"";
                    let diff = demand !== "" ? Number(cover) - Number(demand) : "";
                    diff = Number(cover) > Number(maxDemand) ? "+" + diff : diff;
                    return { columnId: id, included: true, width: 100, text: text,
                        item: ({ employee }) => (
                            isOnPaidLeave(employee.id)
                                ? { type: 'empty-blue', text: `P`, nonEditable: true}
                                : isOnVacation(employee.id) ? { type: 'empty-pink', text: `FE`, nonEditable: true} : { type: "checkbox", checked: worksWeekend(employee.id, d), checkedText: "Ja", uncheckedText: "Nei" }
                        ),
                        demand: demand,
                        cover: cover,
                        diff: diff,
                        isHoliday: holidays.includes(id),
                        turnusWeek: `Turnusuke \n${moment(d).diff(startDate, 'weeks') + 1}`
                    }}))
        setColumns(cols)
    }, [sundays, vacationsByEmployeeAndSunday, paidLeaveByEmployeeAndSunday, employees, toSave, openedDropdownLocation, searchFilters])

    /**
     * UseEffect for generating sundays based on the number if sundays that should be present in the current view
     */
    useEffect(() => {
        const sun = (selectedTask
            ? getWeekdaysFromTask(selectedTask, [0])
            : getWeekdaysFromTask({config: {startDate: startDate, nrOfWeeks: nrOfWeeks}}, [0]));
        if (!selectedTask) {
            setVacationsByEmployeeAndSunday(getPatternsForDaysAndEmployees(sun.slice(viewStartIndex, viewStartIndex + nrInView), PatternCase.VACATION));
            setPaidLeaveByEmployeeAndSunday(getPatternsForDaysAndEmployees(sun.slice(viewStartIndex, viewStartIndex + nrInView), PatternCase.PAID_LEAVE_PATTERN));
        }
        setSundays(sun.slice(viewStartIndex, viewStartIndex + nrInView));
    }, [viewStartIndex])

    /**
     * Helper function for getting the weekend frequency. This is calculated based on nth on the working date pattern
     * @param employeeId
     * @returns {number|number}
     */
    const resolveWeekendFrequency = (employeeId) => {
        const employee = getLocalOrGlobalEmployee(employeeId);
        const pattern = (employee.weekendPatterns || []).find(p => p.kind === 'working');
        return pattern?.disabled === false ? Number(pattern?.series?.nth) : 0;
    }

    /**
     * Helper function for getting the weekend frequency start.
     * @param employeeId
     * @returns {number|number}
     */
    const resolveWeekendFrequencyStartWeek = (employeeId) => {
        const pattern = (getLocalOrGlobalEmployee(employeeId).weekendPatterns || []).find(p => p.kind === 'working');
        return moment(pattern?.series?.from || startDate).endOf('isoWeek').format('YYYY-MM-DD')
    }

    /**
     * Helper function for counting the number of working weekends in the turnus
     * @param employeeId
     * @returns {*[]|number}
     */
    const resolveNumberOfWorkingWeekends = (employeeId) => {
        const employee = getLocalOrGlobalEmployee(employeeId);
        if(!employee.enabled) return 0;
        const weekendPattern = (employee.weekendPatterns || []).find(p => p.kind === 'working');
        const blockedPattern = (employee.weekendPatterns || []).find(p => p.kind === 'notWorking');
        const endDate = moment(startDate).clone().add(nrOfWeeks, 'weeks').subtract(1, 'day');
        const workingSundays = weekendPattern ? getDaysFromDatePattern(dynomite, weekendPattern, startDate, endDate) : [];
        const blockedSundays = blockedPattern ? getDaysFromDatePattern(dynomite, blockedPattern, startDate, endDate) : [];
        return workingSundays.filter(d =>
            !blockedSundays.includes(d)
            && (vacationsByEmployeeAndSunday[d] === undefined || vacationsByEmployeeAndSunday[d][employeeId] !== true)
            && (paidLeaveByEmployeeAndSunday[d] === undefined || paidLeaveByEmployeeAndSunday[d][employeeId] !== true)
        ).length;
    }

    /**
     * Helper function for checking if employee will work a specific Sunday
     * @param employeeId
     * @param sunday
     * @returns {boolean}
     */
    const worksWeekend = (employeeId, sunday) => {
        const employee = getLocalOrGlobalEmployee(employeeId);
        if(!employee.enabled || (employee[patternCase] ?? []).length === 0) return false;
        if(vacationsByEmployeeAndSunday[moment(sunday).format('YYYY-MM-DD')] !== undefined
            && vacationsByEmployeeAndSunday[moment(sunday).format('YYYY-MM-DD')][employeeId] === true) {
            return false;
        }
        if(paidLeaveByEmployeeAndSunday[moment(sunday).format('YYYY-MM-DD')] !== undefined
            && paidLeaveByEmployeeAndSunday[moment(sunday).format('YYYY-MM-DD')][employeeId] === true) {
            return false;
        }
        const working = getDaysFromDatePatterns(dynomite, employee[patternCase], sunday, sunday, ['working']);
        const notWorking = getDaysFromDatePatterns(dynomite, employee[patternCase], sunday, sunday, ['not-working']);
        return working.filter(d => !notWorking.includes(d)).length > 0;
    }

    /**
     * Task select handler method. Once a task is selected, we generate the sundays in the turnus period which
     * again triggers generate of the columns displayed in the weekend planner
     * @param selectedTaskOption
     * @returns {Promise<void>}
     */
    const handleSelectTaskChange = async (selectedTaskOption) => {
        if(!selectedTaskOption) {
            setColumns(undefined)
            reset();
            await initialize(moment().add(1, 'weeks').startOf('isoWeek'), 157);
            return;
        }

        setColumns(undefined);
        let t = await dynoapeAPI.get(`/api/v1/department/${departmentId}/task/${selectedTaskOption.value}`);
        setStartDate(t.config.startDate);
        setNrOfWeeks(t.config.nrOfWeeks);
        const sun = getWeekdaysFromTask(t, [0]);
        setSundayRequirements(getRequirementsFromTask(t, sun, 'SUNDAY'));
        const _startDate = moment(t.config.startDate)
        processAndSetHolidays(department, _startDate, t.config.nrOfWeeks);

        setSelectedTask(t);
        setVacationsByEmployeeAndSunday(getPatternsForDaysAndEmployees(sun, PatternCase.VACATION))
        setPaidLeaveByEmployeeAndSunday(getPatternsForDaysAndEmployees(sun, PatternCase.PAID_LEAVE_PATTERN))
        setSelectedTaskOption({value: selectedTaskOption.value, label: selectedTaskOption.label});
        setViewStartIndex(0)
        setViewFlipSize(2);
        setSundays(sun.slice(0, nrInView));
        scrollHeight()
    }

    /**
     * Change handler for the weekend planner.
     * @param changes
     */
    const handleChanges = (changes) => {
        findAndSetOpenedDropdownCellLocation(changes);
        let localToSave = toSave;
        changes.forEach(ch => {
            const employee = (localToSave.find(e => e.id === ch.rowId) || employees.find(e => e.id === ch.rowId));
            if(['sundaysMaxSequence', 'weekendShiftMaxSequence', 'worksOnlyWeekend'].includes(ch.columnId)) {
                if(ch.columnId === 'sundaysMaxSequence') {
                    return localToSave = [...localToSave.filter(e => e.id !== employee.id), {...employee, sundaysMaxSequence: Number(ch.newCell.text)}];
                } else if (ch.columnId === 'weekendShiftMaxSequence') {
                    const val = ch.newCell.text === '-' ? -1 : Number(ch.newCell.text);
                    if(employee.worksOnlyWeekend && val !== 3) return localToSave;
                    return localToSave = [...localToSave.filter(e => e.id !== employee.id), {...employee, weekendShiftMaxSequence: val}];
                } else {
                    const empl = ch.newCell.checked
                        ? {...employee, worksOnlyWeekend: ch.newCell.checked, weekendShiftMaxSequence: 3}
                        : {...employee, worksOnlyWeekend: ch.newCell.checked}
                    return localToSave = [...localToSave.filter(e => e.id !== employee.id), empl];
                }
            }

            const patterns = (employee.weekendPatterns || []);
            let working = validWeekendPattern(employee.id, patterns.find(p => p.kind === "working"));
            let blocked = patterns.find(p => p.kind === "notWorking");

            if(ch.columnId === 'weekendFrequency') {
                working.series.nth = Number(ch.newCell.text);
                working.series.toggled = working.series.nth > 0;
                working.includeDays = [];
                (blocked || {}).includeDays = []
            } else if (ch.columnId === 'weekendFrequencyStart') {
                if(ch.newCell.isOpen && !ch.previousCell.isOpen) {
                    return;
                }
                const val = moment(ch.newCell.text);
                const getFromText = () => {
                    let [year, week] = ch.newCell.text.split(' Uke ');
                    year = Number(year);
                    week = Number(week);
                    if(isNaN(year) || isNaN(week)) return undefined
                    return moment().year(year).isoWeek(week)
                }
                const from = val.isValid()
                    ? val
                    : getFromText();
                if(!from || !from.isValid()) return;
                working.series.from = from.startOf('isoWeek').format('YYYY-MM-DD');
                working.includeDays = [];
                (blocked || {}).includeDays = []
            } else {
                const date = moment(ch.columnId).format('YYYY-MM-DD');
                if (ch.newCell.checked === true) {
                    blocked = removeSingleDayIfPresent(employee.id, blocked, date)
                    working = addSingleDayIfNotPresent(employee.id, working, date);
                } else {
                    working = removeSingleDayIfPresent(employee.id, working, date);
                    blocked = addSingleDayIfNotPresent(employee.id, blocked, date) || singleDaysDatePattern("Blokkert helg", [date]);
                }
            }
            localToSave = [...localToSave.filter(e => e.id !== employee.id), {...employee, weekendPatterns: [blocked, working].filter(p => p)}]
        });
        setToSave(toSave.filter(e => !localToSave.find(le => le.id === e.id)).concat(localToSave))
    }

    /**
     * Helper function for adding a single day to a date pattern if not present in includeDays or in series
     * @param employeeId
     * @param pattern
     * @param date
     * @returns {*}
     */
    const addSingleDayIfNotPresent = (employeeId, pattern, date) => {
        if(!pattern) return pattern;
        if(getDaysFromDatePattern(dynomite, pattern, date, date, false).length === 0) {
            pattern.includeDays.push(date);
        }
        return pattern;
    }

    /**
     * Helper function for removing a single day from includeDays in a date pattern
     * @param employeeId
     * @param pattern
     * @param date
     * @returns {*}
     */
    const removeSingleDayIfPresent = (employeeId, pattern, date) => {
        if(pattern?.includeDays?.includes(date)) {
            pattern.includeDays = pattern.includeDays.filter(d => d !== date);
        }
        return pattern;
    }

    /**
     * Method triggered when user hits save
     * Read from toSave which contain employees with weekendPatterns. First the weekend patterns are updated, then the employee
     * @returns {Promise<void>}
     */
    const save = async () => {
        setShowSavingModal({ display: true, saveCount: 0, toSave: toSave.length });
        for(const e of toSave) {
            setShowSavingModal({ display: true, saveCount: showSavingModal.saveCount++, toSave: toSave.length, employee: e.name});
            for (const pattern of e.weekendPatterns) {
                const id = pattern.id;
                ['id', 'registered', 'updated'].forEach(field => delete pattern[field])
                if (id) {
                    await dynoapeAPI.put(`/api/v1/department/${departmentId}/employee/${e.id}/pattern/${id}?type=${patternCase}`, pattern);
                } else {
                    await dynoapeAPI.post(`/api/v1/department/${departmentId}/employee/${e.id}/pattern?type=${patternCase}`, pattern);
                }
            }
            let employee = {
                sundaysMaxSequence: e.sundaysMaxSequence,
                worksOnlyWeekend: e.worksOnlyWeekend,
                weekendShiftMaxSequence: e.weekendShiftMaxSequence}
            if(employee.worksOnlyWeekend) {
                const daySegments = e.restShiftTypes?.map(ds => ds.daySegment) || [];
                employee = {...employee,
                    shiftMaxSequence: 3,
                    dayShiftMaxSequence: daySegments.includes("D") ? 3 : employee.dayShiftMaxSequence,
                    eveningShiftMaxSequence: daySegments.includes("A") ? 3 : employee.eveningShiftMaxSequence,
                    longShiftMaxSequence: daySegments.includes("L") ? 3 : employee.longShiftMaxSequence,
                    nightShiftMinSequence: daySegments.includes("N") ? 3 : employee.nightShiftMinSequence,
                    nightShiftMaxSequence: daySegments.includes("N") ? 3 : employee.nightShiftMaxSequence
                }
            }
            await dynoapeAPI.emPatch(`/api/v1/department/${departmentId}/employee/${e.id}`, employee);
        }
        setToSave([]);
        setShowSavingModal({ display: false, saveCount: 0, toSave: 0, employee: null })
        setEmployees((await dynoapeAPI.get(`/api/v1/department/${departmentId}/employees${PatternCase.getIncludeQueryParams(patternCase)}`)).filter(e => e.enabled).sort(function (a, b) {
            return a.priority === b.priority ? new Date(a.registered) - new Date(b.registered) : a.priority - b.priority
        }))
    }

    return (
        <PlannerWrapper>
            <p><label style={{fontSize: "16px"}}>
                Her kan du planlegge hvilke helger hver ansatt skal jobbe
            </label></p>
            <br/>
            <Flex flexDirection="row">
                <Button primary style={{marginTop: "0px" }} onClick={() => save()}
                        data-for="save"
                        disabled={toSave.length === 0}
                        data-tip="Lagre dine helge-spesifikasjoner for de ansatte">Lagre</Button>
                <Tooltip id="save" />
                {taskSelect(handleSelectTaskChange)}
                {searchBar()}
                {columns &&
                    <WeekJumper flipSize={viewFlipSizeRef.current} setFlipSize={setViewFlipSize}
                                viewStartIndex={viewStartIndex} setViewStartIndex={setViewStartIndex}
                                nrInView={nrInView} jumpTitle="Hopp antall uker" totalNrOfColumns={nrOfWeeks}>
                    </WeekJumper>
                }
            </Flex>
            {columns === undefined && <p>Laster...</p>}
            {columns && department && <ReactGridWrapper>
                <ReactGrid
                    customCellTemplates={{
                        'dropdown-number': DropdownCellTemplate,
                        'checkbox': CheckboxCellTemplate,
                        'dropdown': DropdownSelectCellTemplate,
                        'empty': DisabledInfoCellTemplate,
                        'empty-pink': DisabledInfoCellTemplate,
                        'empty-blue': DisabledInfoCellTemplate
                    }}
                    rows={[
                        ...(selectedTask ? [getReactGridHeaderRow(columns, 'turnusWeek', 'turnusWeek', 50)].map(row => ({...row, cells: row.cells.map(cell => ({...cell, style: {...cell.style, color: cell.isHoliday ? "red" : "black"}}))})) : [] ),
                        [getReactGridHeaderRow(columns, 'header', 'text', 60)]
                            .map(row => ({...row, cells: row.cells.map(cell => ({...cell, style: {...cell.style, color: cell.isHoliday ? "red" : "black"}}))}))[0],
                        ...getReactGridRows(columns, employees.map(e => getLocalOrGlobalEmployee(e.id)), sundays[0], sundays[sundays.length -1]).map((row, idx) => idx % 2 !== 0 ?
                            { ...row, cells: row.cells.map(cell => ({ ...cell, style: cell.type === 'empty-pink' ? { background: 'pink' } : cell.type === 'empty-blue' ? { background: 'lightblue' } : {  background: 'rgba(0,0,0,0.07)'} })) }
                            : { ...row, cells: row.cells.map(cell => ({ ...cell, style: cell.type === 'empty-pink' ? {  background: 'pink'} : cell.type === 'empty-blue' ? { background: 'lightblue' } : cell.style })) }
                        ),
                        getReactGridHeaderRow(columns, 'demand', 'demand'),
                        getReactGridHeaderRow(columns, 'cover', 'cover'),
                        [getReactGridHeaderRow(columns, 'diff', 'diff')]
                            .map(row => ({...row, cells: row.cells
                                    .map(cell => ({...cell, text: cell.text.replaceAll('+', ''), style: {color:
                                                cell.text === "Differanse" ? "black" :
                                                    cell.text.includes("+") ? "blue" :
                                                    Number(cell.text) < 0 ? "red": "green"}}))}))[0]
                    ]}
                    columns={columns}
                    stickyTopRows={selectedTask ? 2 : 1}
                    stickyBottomRows={3}
                    stickyLeftColumns={4}
                    onCellsChanged={handleChanges}
                    enableFillHandle
                    enableRangeSelection
                    enableRowSelection
                />
            </ReactGridWrapper>}
            {showSavingModal.display && saveModal()}
            {routerPrompt(toSave.length > 0)}
            {dynomiteErrorPanel(dynomite, configErrors, dynomiteInternalError)}
            {dynomiteErrorPanel(dynomite, configWarnings, dynomiteInternalError, 0, () => {}, true)}
        </PlannerWrapper>)
};