
import React, { useState, useEffect } from "react";
import {InputRow} from "../lib/styled-components";

export const TaskShiftSums = ({ config, shifts, InputDivider }) => {
  const [shiftCalculations, setShiftCalculations] = useState({});

  useEffect(() => {
    let shiftCalcs = {};
    let nrOfWeeks = parseInt(config.nrOfWeeks);
    Object.keys(shifts).map(shiftId => {
      let min = 0;
      let max = 0;
      Object.keys(config.weeklyCoverDemands).forEach(day => {
        Object.keys(config.weeklyCoverDemands[day]).forEach(sh => {
          if(config.shiftTypes[sh].daySegment === shiftId) {
            min += config.weeklyCoverDemands[day][sh].minimumNrOfEmployees;
            max += config.weeklyCoverDemands[day][sh].maximumNrOfEmployees;
          }
        })
      });
      shiftCalcs[shiftId] = {min: min * nrOfWeeks, max: max * nrOfWeeks};
    });
    setShiftCalculations(shiftCalcs);
  }, [config]);

  const shiftColumns = Object.keys(shifts).map(shiftId =>
    <InputDivider key={shiftId}>
      <p><label>{shifts[shiftId]}</label></p>
      <p>Minimum: <b>{shiftCalculations[shiftId]?.min}</b> - Maksimum: <b>{shiftCalculations[shiftId]?.max}</b></p>
    </InputDivider>
  );

  return (
    <div>
      <InputRow>
        {shiftColumns}
      </InputRow>
    </div >
  );
};
