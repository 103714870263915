/* eslint-disable no-unused-vars */
import React from "react";
import { getCellProperty, keyCodes } from '@silevis/reactgrid';

export const CheckboxCellTemplate = {

  getCompatibleCell(uncertainCell) {
    const checked = getCellProperty(uncertainCell, 'checked', 'boolean');
    const text = checked ?
      uncertainCell.checkedText ? uncertainCell.checkedText : '1' :
      uncertainCell.uncheckedText ? uncertainCell.uncheckedText : '';
    return { ...uncertainCell, checked: !!checked, value: checked ? 1 : NaN, text };
  },

  handleKeyDown(cell, keyCode, ctrl, shift, alt) {
    if (!shift && (keyCode === keyCodes.SPACE || keyCode === keyCodes.ENTER))
      return { cell: this.getCompatibleCell(this.toggleCheckboxCell(cell)), enableEditMode: false }
    return { cell, enableEditMode: false }
  },

  toggleCheckboxCell(cell) {
    return this.getCompatibleCell({ ...cell, checked: !cell.checked })
  },

  update(cell, cellToMerge) {
    const checked = cellToMerge.text === "Ja" ? true : cellToMerge.text === "Nei" ? false : cellToMerge.type === 'checkbox' ? cellToMerge.checked : !!cellToMerge.value;
    return this.getCompatibleCell({ ...cell, checked });
  },

  getClassName(cell) {
    return cell.className ? cell.className : '';
  },

  render(cell, isInEditMode, onCellChanged) {
    return (
      <label>
        <input
          type='checkbox'
          checked={cell.checked}
          onChange={e => onCellChanged(this.toggleCheckboxCell(cell), true)}
        />
        <span></span>
      </label>
    )
  }

}