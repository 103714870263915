import React, { useState } from "react";
import {Button, Card, Tabs, Tab, Modal} from "rendition";
import { Header, EmployeeTable } from "../components";
import { NavLink, useParams, useLocation } from "react-router-dom";
import { BackButtonEmployee } from "../components/buttons";
import AbsencePredictor from "./absencePredictor";
import Tooltip from "../lib/tooltip";
import { PatternCase } from "../lib/pattern-case";
import EmployeeDatePatternCases from "../components/date_pattern/employee-date-pattern-cases";

const Employees = () => {
  const { departmentId } = useParams();
  const { hash } = useLocation();
  const [activeIndex, setActiveIndex] = useState(
    hash === "bemanningsregler" ? 1 : 0
  );
  const [desiredActiveIndex, setDesiredActiveIndex] = useState(undefined);
  const [somePatternCaseIsDirty, setSomePatternCaseIsDirty] = useState(false);
  const onActive = (i) => {
    if (!somePatternCaseIsDirty) {
      setActiveIndex(i);
    } else {
      setDesiredActiveIndex(i);
    }
  };

  return (
    <div>
      <Header
        title={"Ansatte"}
        showLicenseEmployeeInfo={true}
        withTooltip
        tooltipId="employees-header"
        tooltipContent="Her finner du oversikt over alle ansatte i din avdeling og kan legge inn eller slette ansatte, samt spesifisere hvordan de skal arbeide"
      >
        <BackButtonEmployee id={departmentId} />
      </Header>
      <div className="content">
        <Tooltip id="employees-tab" />
        <Tooltip id="employees-vacation" />
        <Tooltip id="employees-paid-leave" />
        <Tooltip id="employees-weekend-work" />
        <Tooltip id="employees-holidays" />
        <Tooltip id="employees-blocked-patterns" />
        <Tooltip id="employees-absence-predictor" />
        <Tabs activeIndex={activeIndex} onActive={onActive}>
          <Tab
            title="Ansatte"
            data-for="employees-tab"
            data-tip="Liste over alle ansatte og ansattkort"
          >
            <Card
              style={{
                width: "1440px",
                padding: "40px",
                overflow: "hidden",
                borderTopLeftRadius: "0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  height: "50px",
                  top: "0px",
                  position: "absolute",
                  width: "100%",
                  flexDirection: "row-reverse",
                }}
              >
                <div style={{ marginTop: "40px", marginRight: "80px" }}>
                  <NavLink className="nav-link" to={`./rediger-ansatte`}>
                    <Button
                      primary
                      mb="10px"
                      data-for="employees-edit-tooltip"
                      data-tip="Her kan du legge til en liste med oversikt over alle ansatte og spesifisere hvordan de skal arbeide. Tips - dersom du har en ansattliste i excel, med stillingsprosent og stilling kan du kopiere denne inn her. Merk da kolonnene i excel arket, kopier, og lim inn her (lim inn= ctrl+V)"
                    >
                      Legg til/Rediger ansatte
                    </Button>
                  </NavLink>
                  <Tooltip id="employees-edit-tooltip" />

                  <NavLink className="nav-link" to={`./ansatte/ny`}>
                    <Button
                      icon={<p>+</p>}
                      primary
                      mb="10px"
                      data-for="employees-add-tooltip"
                      data-tip="Her kan du legge til en og en ansatt i avdeling og spesifisere hvordan denne skal arbeide"
                    >
                      Legg til ny ansatt
                    </Button>
                  </NavLink>
                  <Tooltip id="employees-add-tooltip" />
                </div>
              </div>
              <EmployeeTable style={{ margin: "0 10px" }} />
            </Card>
          </Tab>
          <Tab
              title={PatternCase.translate(PatternCase.WEEKEND_PATTERN)}
              data-for="employees-weekend-work"
              data-tip="Her kan du sette opp hvilke helger de ansatte skal jobbe"
          >
            <Card style={{ width: "calc(90vw - 40px)", maxWidth: "2680px", borderTopLeftRadius: "0px" }}>
              <h3>{PatternCase.translate(PatternCase.WEEKEND_PATTERN)}</h3>
              <EmployeeDatePatternCases somePatternCaseIsDirty={somePatternCaseIsDirty} setSomePatternCaseIsDirty={setSomePatternCaseIsDirty} patternCase={PatternCase.WEEKEND_PATTERN}></EmployeeDatePatternCases>
            </Card>
          </Tab>
          <Tab
              title={PatternCase.translate(PatternCase.HOLIDAY_PATTERN)}
              data-for="employees-holidays"
              data-tip="Her kan du planlegge helligdagsarbeid"
          >
            <Card style={{ width: "calc(90vw - 40px)", maxWidth: "2900px", borderTopLeftRadius: "0px" }}>
              <h3>{PatternCase.translate(PatternCase.HOLIDAY_PATTERN)}</h3>
              <EmployeeDatePatternCases somePatternCaseIsDirty={somePatternCaseIsDirty} setSomePatternCaseIsDirty={setSomePatternCaseIsDirty} patternCase={PatternCase.HOLIDAY_PATTERN}></EmployeeDatePatternCases>
            </Card>
          </Tab>
          <Tab
              title={PatternCase.translate(PatternCase.PAID_LEAVE_PATTERN)}
              data-for="employees-paid-leave"
              data-tip="Her kan du sette opp ansattes permisjon"
          >
            <Card style={{ width: "calc(90vw - 40px)", maxWidth: "1750px",  borderTopLeftRadius: "0px" }}>
              <h3>{PatternCase.translate(PatternCase.PAID_LEAVE_PATTERN)}</h3>
              <EmployeeDatePatternCases somePatternCaseIsDirty={somePatternCaseIsDirty} setSomePatternCaseIsDirty={setSomePatternCaseIsDirty} patternCase={PatternCase.PAID_LEAVE_PATTERN}></EmployeeDatePatternCases>
            </Card>
          </Tab>
          <Tab
              title={PatternCase.translate(PatternCase.VACATION)}
              data-for="employees-vacation"
              data-tip="Her kan du sette opp ansattferie"
          >
            <Card style={{ width: "calc(90vw - 40px)", maxWidth: "1750px",  borderTopLeftRadius: "0px" }}>
              <h3>{PatternCase.translate(PatternCase.VACATION)}</h3>
              <EmployeeDatePatternCases somePatternCaseIsDirty={somePatternCaseIsDirty} setSomePatternCaseIsDirty={setSomePatternCaseIsDirty} patternCase={PatternCase.VACATION}></EmployeeDatePatternCases>
            </Card>
          </Tab>
          <Tab
              title={PatternCase.translate(PatternCase.BLOCKED_PATTERN)}
              data-for="employees-blocked-patterns"
              data-tip="Her kan du sette opp fraværet til den ansatte"
          >

            <Card style={{ width: "1440px", borderTopLeftRadius: "0px" }}>
              <h3>{PatternCase.translate(PatternCase.BLOCKED_PATTERN)}</h3>
              <EmployeeDatePatternCases somePatternCaseIsDirty={somePatternCaseIsDirty} setSomePatternCaseIsDirty={setSomePatternCaseIsDirty} patternCase={PatternCase.BLOCKED_PATTERN}></EmployeeDatePatternCases>
            </Card>
          </Tab>
          <Tab
              title="Fraværspredikering"
              data-for="employees-absence-predictor"
              data-tip="Her får du en oversikt på uplanlagt fravær som er i din avdeling"
          >
            <Card style={{ width: "1440px", borderTopLeftRadius: "0px" }}>
              <h3>{"Oversikt over fravær"}</h3>
              <AbsencePredictor departmentId={departmentId} />
            </Card>
          </Tab>
          {desiredActiveIndex !== undefined && (
              <Modal
                  title={"Er du sikker på at du vil forlate siden"}
                  cancel={() => setDesiredActiveIndex(undefined)}
                  cancelButtonProps={{
                    children: "Nei, bli på siden",
                  }}
                  done={() => {
                    setActiveIndex(desiredActiveIndex);
                    setDesiredActiveIndex(undefined);
                  }}
                  action="Ja, forlat siden"
              >
                <p style={{ color: "red" }}>Alle endringer vil gå tapt</p>
              </Modal>
          )}
        </Tabs>
      </div>
    </div>
  );
};

export default Employees;
