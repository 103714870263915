import React from "react";
import axios from "axios";
import { Avatar, Button } from "rendition";
import { Header } from "../components";

import { useAuth0 } from "@auth0/auth0-react";

const Profile = () => {
  const { user } = useAuth0();
  const { name, picture, email } = user;

  const triggerPassordReset = async () => {
    try {
      let response = await axios.request({
        method: 'POST',
        url: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
        headers: { 'content-type': 'application/json' },
        data: {
          client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
          email: email,
          connection: 'Username-Password-Authentication'
        }
      });
      window.alert(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Header title={"Profile"} />
      <div className="content">
        <h3>Profile - protected route</h3>
        <div className="row align-items-center profile-header">
          <div className="col-md text-center text-md-left">
            <Avatar emphasized src={picture} />
            <h2>Name: {name}</h2>
            <p className="lead text-muted">Email: {email}</p>
          </div>
        </div>
        <div className="row">
          <pre className="col-12 text-light bg-dark p-4">
            {JSON.stringify(user, null, 2)}
          </pre>
        </div>
        <Button type="button"
          primary
          onClick={triggerPassordReset}>
          Reset password
        </Button>
      </div>
    </div>

  );
};

export default Profile;