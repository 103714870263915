import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Divider, Flex } from "rendition"; 
import { Collapsible } from 'grommet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';

const AccordionRowContainer = styled.div`
    border-radius: 10px;
    padding: 16px;
    transition: all 0.2s cubic-bezier(.25,.8,.25,1);
    // border: ${props => (props.open || props.hover) ? "1px solid #E5E7f0" : "1px solid rgba(0,0,0,0)"};
    box-shadow: ${props => props.open
        ? props => props.hover
            // ? "0 8px 20px rgba(0,0,0,0.08), 0 6px 6px rgba(0,0,0,0.115)"
            // ? "0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.12)"
            // ? "0 14px 28px rgba(0,0,0,0.125), 0 10px 10px rgba(0,0,0,0.11)"
            // ? "0 19px 38px rgba(0,0,0,0.15), 0 15px 12px rgba(0,0,0,0.11)"
            ? "0 31px 41px 0 rgb(32 42 53 / 20%), 0 2px 16px 0 rgb(32 42 54 / 8%)"
            // : "0 3px 6px rgba(0,0,0,0.08), 0 3px 6px rgba(0,0,0,0.115)"
            : "0 3px 6px 0 rgb(23 24 24 / 8%), 0 4px 10px 0 rgb(23 24 24 / 12%)"
        : props => props.hover
            // ? "0 3px 6px rgba(0,0,0,0.08), 0 3px 6px rgba(0,0,0,0.115)"
            ? "0 3px 6px 0 rgb(23 24 24 / 8%), 0 4px 10px 0 rgb(23 24 24 / 12%)"
            // : "0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.12)"
            : "0 0 0 rgba(0,0,0,0), 0 0 0 rgba(0,0,0,0)"
        }
    };
    & + hr {
        border-top: ${props => (props.hover || props.open) && "1px solid rgba(0,0,0,0)"};
        transition: all 0.2s cubic-bezier(.25,.8,.25,1);
    }
`;
const AccordionRowLabel = styled.div`
    cursor: pointer;
    margin: -16px;
    padding: 16px;
    z-index: 2;
`;
const AccordionRowPanel = styled.div`
    pointer-events: None;
    z-index: 1;
`;

const AccordionRow = ({startOpen, scrollIntoView, label, panel, index, hidePanelDivider}) => {
    const [open, setOpen] = useState(!!startOpen);
    const [hover, setHover] = useState(false);
    const ref = React.useRef(null);

    const scrollRefIntoView = () => {
        const scroll = () => {
            if (ref.current) {
                ref.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            }
        }

        scroll();

        // Continue to scroll for another ~200 ms while animation plays
        const step = 1000 / 30 // Scoll roughly 30 times a second
        const step_count = Math.ceil(210 / step);
        for (let i = 0; i < step_count; i++) {
            const delay = (i + 1) * step;
            setTimeout(scroll, delay);
        }
    }

    const onClick = () => {
        const isOpen = !open;
        setOpen(isOpen);
        if (isOpen) {
            scrollRefIntoView();
        }
    }

    useEffect(() => {
        if (scrollIntoView) {
            scrollRefIntoView();
        }
    }, [scrollIntoView]);

    return <div ref={ref}>
        {
            index != 0 && <Divider style={{
                ...(hover || open) ? {borderTop:  "1px solid rgba(0,0,0,0)"} : {}
            }} />
        }
        <AccordionRowContainer
                open={open}
                hover={hover}
                onClick={onClick}>
            <AccordionRowLabel
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)} >
                <Flex alignItems="center" justifyContent="space-between">
                    <div style={{width: "100%"}}>{label}</div>
                    <FontAwesomeIcon
                        color='#ccc'
                        icon={open ? faChevronUp : faChevronDown}
                        style={{paddingLeft: "1em", paddingRight: "1em"}} />
                </Flex>
            </AccordionRowLabel>
            <AccordionRowPanel onClick={e => e.stopPropagation()} >
                <Collapsible open={open}>
                    <Divider style={{marginTop: "16px", opacity: hidePanelDivider ? 0 : 1}}/>
                    <Box style={{pointerEvents: "auto"}}>
                        {panel}
                    </Box>
                </Collapsible>
            </AccordionRowPanel>
        </AccordionRowContainer>
    </div>;
}

export default ({items, hidePanelDivider}) => <Box>
    {items.map((item, i) => <AccordionRow key={item.key || i} index={i} hidePanelDivider={hidePanelDivider} {...item} />)}
</Box>