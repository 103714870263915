/* eslint-disable no-unused-vars */
import React from "react";

export const DisabledInfoCellTemplate = {

  getCompatibleCell(uncertainCell) {
    return uncertainCell;
  },

  getClassName(cell) {
    return cell.className ? cell.className : '';
  },

  isFocusable(cell) {
    return false
  },

  render(cell, isInEditMode, onCellChanged) {
    return (
        <div>
          <i>{cell.text}</i>
        </div>
    )
  }
}