import React, {useContext, useEffect, useMemo, useState} from "react";
import {Box, Divider, Flex, Txt} from "rendition";
import Accordion from "../accordion";
import BlockedPatternsPickers from "./blocked-patterns-picker";
import moment from "moment/moment";
import Tooltip from "../../lib/tooltip";
import dynomiteContext from "../dynomite";
import {
    getDaysFromDatePattern,
    getDaysFromDatePatterns,
    rollupAndReduce,
    fullSchemeYlOrRd,
    monthsShort
} from "../../lib/common";
import CalendarHeatmap from "../vis-components/calendar-heatmap";
import EmployeeFilter from "./employee-filter";
import { PaginationJumper } from "../../lib/styled-components";

/**
 * Component to show all blocked patterns of all employees together with
 * vacations and paid leaves.
 * 
 * @param {string} departmentId - Id of the department
 * @param {Employee[]} employees - List of employees
 * @param {function} setEmployees - Callback to update the list of employees
 * @param {Position[]} positions - List of positions
 * @param {Trait[]} traits - List of traits
 * @param {function} reset - Callback to reset the component
 */
export default ({departmentId, employees, setEmployees, positions, traits, reset}) => {
    const dynomite = useContext(dynomiteContext);
    const [searchFilters, setSearchFilters] = useState({
        employee: undefined,
        position: undefined,
        traits: [],
        startDate: undefined,
        endDate: undefined,
        kinds: ['not-working', 'vacation', 'paid-leave'],
        daySegments: ['d', 'a', 'l', 'n'],
    });
    const [visYear, setVisYear] = useState(new Date().getFullYear());

    /**
     * Reset the component at the initial load.
     */
    useEffect(() => {return () => { reset();}}, []);

    /**
     * Adjust filtered employees (and their date patterns) based on the change
     * of filter parameters.
     * 
     * Filterin is cached for any non-filtering re-render.
     */
    const filteredEmployees = useMemo(
        () => employees
            .filter(e => 
                !searchFilters.employee
                || e.name.toLowerCase().includes(searchFilters.employee.toLowerCase())
            )
            .filter(e =>
                !searchFilters.position
                || searchFilters.position === e.position
            )
            .filter(e =>
                searchFilters.traits.length === 0
                ||  searchFilters.traits.some(t => e.traits.includes(t))
            )
            .reduce(
                (acc, e) => {
                    const blocked = (
                        searchFilters.kinds.includes('not-working') ? e.blockedPatterns || [] : []
                    ).filter(p =>
                        (!searchFilters.startDate && !searchFilters.endDate)
                        || getDaysFromDatePattern(
                                dynomite,
                                {...p, disabled: false},
                                searchFilters.startDate || moment(`${visYear}-01-01`),
                                searchFilters.endDate || moment(`${visYear}-12-31`)
                        ).length > 0
                    )
                    const vacationPatterns = (
                        searchFilters.kinds.includes('vacation') ? e.vacationPatterns || []: []
                    ).filter(p =>
                        (!searchFilters.startDate && !searchFilters.endDate)
                        || getDaysFromDatePattern(
                            dynomite,
                            {...p, disabled: false},
                            searchFilters.startDate || moment(`${visYear}-01-01`),
                            searchFilters.endDate || moment(`${visYear}-12-31`)
                        ).length > 0
                    )
                    const paidLeavePatterns = (
                        searchFilters.kinds.includes('paid-leave') ? e.paidLeavePatterns || [] : []
                    ).filter(p =>
                        (!searchFilters.startDate && !searchFilters.endDate)
                        || getDaysFromDatePattern(
                            dynomite,
                            {...p, disabled: false},
                            searchFilters.startDate || moment(`${visYear}-01-01`),
                            searchFilters.endDate || moment(`${visYear}-12-31`)
                        ).length > 0
                    )
                    // if date or kind is set filter employees as well
                    if (!(
                            (searchFilters.startDate || searchFilters.endDate)
                            || searchFilters.kinds.length !== 3
                        )
                        || (
                            blocked.length > 0
                            || vacationPatterns.length > 0
                            || paidLeavePatterns.length > 0
                        )
                    ) {
                        acc.push({
                            ...e,
                            blockedPatterns: blocked,
                            vacationPatterns: vacationPatterns,
                            paidLeavePatterns: paidLeavePatterns
                        })
                    }
                    return acc
                },
                []
            ),
        [searchFilters]
    )

    return (
        <>
            <Flex
                justifyContent="space-between"
                style={{ 
                    gap: "0.5em"
                }}
            >
                <EmployeeFilter
                    name={{
                        value: searchFilters.employee,
                        onChange: name => setSearchFilters({
                            ...searchFilters,
                            employee: name
                        })
                    }}
                    positions={{
                        value: positions,
                        onChange: position => setSearchFilters({
                            ...searchFilters,
                            position: position
                        }) 
                    }}
                    traits={{
                        value: traits,
                        onChange: traits => setSearchFilters({
                            ...searchFilters,
                            traits: traits
                        })
                    }}
                    dateRange={{
                        startDate: searchFilters.startDate,
                        endDate: searchFilters.endDate,
                        onChangeStart: date => setSearchFilters({
                            ...searchFilters,
                            startDate: date
                        }),
                        onChangeEnd: date => setSearchFilters({
                            ...searchFilters,
                            endDate: date
                        }),
                    }}
                    kinds={{
                        value: searchFilters.kinds,
                        onChange: kind => setSearchFilters({
                            ...searchFilters,
                            kinds: searchFilters.kinds.includes(kind) ?
                                searchFilters.kinds.filter(el => el !== kind) :
                                [...searchFilters.kinds, kind]
                        })
                    }}
                    daySegments={{
                        value: searchFilters.daySegments,
                        onChange: ds => setSearchFilters({
                            ...searchFilters,
                            daySegments: searchFilters.daySegments.includes(ds) ?
                                searchFilters.daySegments.filter(el => el !== ds) :
                                [...searchFilters.daySegments, ds]
                        })
                    }}
                    customComponents={[<Box key='patterns-vis-year' style={{ flex: '0 0 95px'}}>
                        <Txt style={{ marginBottom: '1.25em' }}
                            data-for='patterns-vis-year'
                            data-tip='Datoen av år hvis tilpasningen skal vises'
                        >Se år</Txt>
                        <Tooltip id='patterns-vis-year' />
                        <PaginationJumper
                            value={visYear}
                            onLeftChange={(value) => setVisYear(value - 1)}
                            onRightChange={(value) => setVisYear(value + 1)}
                        />
                    </Box>]}
                />
            </Flex>
            <CalendarHeatmap 
                data={rollupAndReduce(
                    filteredEmployees.map(e => 
                        getDaysFromDatePatterns(
                            dynomite,
                            e.blockedPatterns
                                .concat(e.vacationPatterns)
                                .concat(e.paidLeavePatterns),
                            Math.max(...[
                                moment(`${visYear}-01-01`),
                                searchFilters.startDate ?
                                    moment(searchFilters.startDate)
                                    : moment(`${visYear}-01-01`),
                            ]),
                            Math.min(...[
                                moment(`${visYear}-12-31`),
                                searchFilters.endDate ?
                                    moment(searchFilters.endDate)
                                    : moment(`${visYear}-12-31`),
                            ]),
                            searchFilters.kinds,
                            searchFilters.daySegments
                        ).map(day => ({
                            Date: day,
                            Value: e.id
                        }))
                    ),
                    (day) => ({
                        Date: day[0].Date,
                        Value: day.reduce(
                            (acc, v) => acc.add(v.Value),
                            new Set()
                        ).size
                    })
                )}
                inMonths={
                    Array.from(Array(12).keys()).map(mId => `${visYear}-${mId + 1}`)
                }
                monthsLabels={Array.from(Array(12).keys()).reduce((a, v) => ({
                        ...a,
                        [`${visYear}-` + `0${v + 1}`.slice(-2)]: monthsShort[v]
                    }),
                    {}
                )}
                width={1350}
                height={250}
                margin={{top: 15, left: 0, bottom: 10, right: 0}}
                thresholdScale={{
                    domain: [
                        Math.round(employees.length * 0.1),
                        Math.round(employees.length * 0.2),
                        Math.round(employees.length * 0.3),
                        Math.round(employees.length * 0.4),
                        Math.round(employees.length * 0.5)
                    ]
                }}
                colorScheme={fullSchemeYlOrRd}
                undefinedColor={'#ffffff'}
                legendLabel={'Antall ansatte som er fraværende/på ferie/permisjon'}
                hidden={false}
            />
            <Divider />
            <Box>
                <Accordion
                    hidePanelDivider={true}
                    keyFunc={(item) => item.id}
                    items={
                        filteredEmployees.map(empl => ({
                            key: `employee-row-${empl.id}`,
                            startOpen: false,
                            scrollIntoView: false,
                            label: <Flex
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box style={{ "flex": "1 0 10%"}}>
                                    <Txt style={{ "marginBottom": "1em" }}>{empl.name}</Txt>
                                </Box>
                                <Box style={{ "flex": "1 0 10%"}}>
                                    <Txt style={{ "marginBottom": "1em" }}>{empl.vacancyRate + "%"}</Txt>
                                </Box>
                                <Box style={{ "flex": "1 0 10%"}}>
                                    <Txt style={{ "marginBottom": "1em" }}>{empl.restPosition?.name}</Txt>
                                </Box>
                            </Flex>,
                            panel: <BlockedPatternsPickers
                                employee={empl}
                                setEmployees={setEmployees}
                                departmentId={departmentId}>
                            </BlockedPatternsPickers>
                        })
                    )}
                />
            </Box>
        </>
    )
};
