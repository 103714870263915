import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./app";
import { BrowserRouter as Router } from "react-router-dom";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import { Provider, NotificationsContainer } from "rendition";
import { lightTheme } from "./themes";

require('typeface-roboto');
require('typeface-montserrat');
require('typeface-montserrat-alternates');

import "./index.css";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<Router getUserConfirmation={() => {
  /* Empty callback to block the default browser prompt */
}}>
  <Auth0ProviderWithHistory>
    <Provider theme={lightTheme}>
      <NotificationsContainer />
      <App />
    </Provider>
  </Auth0ProviderWithHistory>
</Router>);
