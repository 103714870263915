import React from 'react';

export const DropdownCellTemplate = {
    getCompatibleCell(uncertainCell) {
        return {
            ...uncertainCell,
            text: uncertainCell.text || '',
            value: uncertainCell.values?.length || 0,
            values: uncertainCell.values || [],
            disabled: uncertainCell.disabled,
        }
    },
    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
    },
    render(cell, isInEditMode, onCellChanged) {
        return (<div style={{ margin: "auto", height: "initial" }}>
            <select
                style={{ "padding": "0px", "height": "20px" }}
                disabled={cell.disabled}
                className={cell.className}
                onPointerDown={e => e.stopPropagation()}
                value={cell.text}
                onChange={e =>
                    onCellChanged(
                        this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
                        true
                    )
                }
            >
                {cell.values.map((value, idx) => <option key={idx} value={value}>{value}</option>)}
            </select>
        </div>
        );
    },
}