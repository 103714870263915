
import React, {useState} from "react";
import { Header, EmployeeSheet } from "../components";
import { BackButton } from "../components/buttons";

const EmployeesBulk = () => {
  const [refresh, setRefresh] = useState(0);
  return (
    <div>
      <Header title={"Ansatte"} showLicenseEmployeeInfo={true} refresh={refresh}
        withTooltip
        tooltipId="employees-header"
        tooltipContent="Opprett ansattliste:<br />
        Velger “Legg til flere rader”<br />
        Sett inn det antall ansatte du vil opprette<br />
        Det er nå to måter å opprette ansatte på:<br />
        Kopier navn, stilling eller stillingsprosent fra et excel ark. Marker de kolonnene du vil kopiere, høyreklikk og velg “kopier”.<br />
        Sett markøren i den første gule cellen under navn i den tomme ansattlisten i Dynamon. Trykk ctrl+V. Ansattlisten er da opprettet i Dynamon<br />
        Legg inn en og en ansatt<br />
        <br />
        Dersom du skal legge til flere ansatte, trykk på”Legg til flere ansatte”
        ">
        <BackButton />
      </Header>
      <div style={{ margin: "20px 10px 20px 150px", padding: "10px" }}>
        <EmployeeSheet setRefresh={setRefresh}/>
      </div>
    </div>
  );
};

export default EmployeesBulk;
