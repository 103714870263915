import React from "react";
import {
  Profile,
  Departments,
  Department,
  Employees,
  EmployeesBulk,
  Tasks,
  Task,
  CreateEmployee,
  EditEmployee,
  Results,
  DepartmentSettings,
} from "../views";
import { PublicSidebar, Sidebar } from "../components";
import OrgEmployeePage from "../components/org-employee-page";
import Runners from "../views/runners";

const routes = [
  {
    path: "/profil",
    exact: true,
    main: () => <Profile />,
    protected: true,
    breadcrumb: "Profil",
  },
  {
    path: "/avdelinger/:departmentId/turnusplaner/:taskId",
    exact: true,
    sidebar: () => <Sidebar />,
    main: () => <Results />,
    protected: true,
    breadcrumb: "Turnusplan",
  },
  {
    path: "/avdelinger/:departmentId/kjør-bemanningsplaner",
    exact: true,
    sidebar: () => <Sidebar />,
    main: () => <Runners />,
    protected: true,
    breadcrumb: "Kjøreplan",
  },
  {
    path: "/avdelinger/:departmentId/bemanningsplaner/:taskId",
    exact: true,
    sidebar: () => <Sidebar />,
    main: () => <Task />,
    protected: true,
    breadcrumb: "Bemanningsplan",
  },
  {
    path: "/avdelinger/:departmentId/bemanningsplaner",
    exact: true,
    sidebar: () => <Sidebar />,
    main: () => <Tasks />,
    protected: true,
    breadcrumb: "Bemanningsplaner",
  },
  {
    path: "/avdelinger/:departmentId/kjør-bemanningsplaner",
    exact: true,
    sidebar: () => <Sidebar />,
    main: () => <Runners />,
    protected: true,
    breadcrumb: "Knytt bemanningsplaner",
  },
  {
    path: "/avdelinger/:departmentId/ansatte/ny",
    exact: true,
    sidebar: () => <Sidebar />,
    main: () => <CreateEmployee />,
    protected: true,
    breadcrumb: "Ansatt",
  },
  {
    path: "/avdelinger/:departmentId/ansatte/:employeeId/rediger",
    exact: true,
    sidebar: () => <Sidebar />,
    main: () => <EditEmployee />,
    protected: true,
    breadcrumb: "Ansatt",
  },
  {
    path: "/avdelinger/:departmentId/ansatte/:employeeId",
    exact: true,
    sidebar: () => <Sidebar />,
    main: () => <EditEmployee />,
    protected: true,
    breadcrumb: "Ansatt",
  },
  {
    path: "/avdelinger/:departmentId/ansatte/:employeeId/knytt",
    exact: true,
    sidebar: () => <Sidebar />,
    main: () => <OrgEmployeePage />,
    protected: true,
    breadcrumb: "Ansatt",
  },
  {
    path: "/avdelinger/:departmentId/ansatte",
    exact: true,
    sidebar: () => <Sidebar />,
    main: () => <Employees />,
    protected: true,
    breadcrumb: "Ansatte",
  },
  {
    path: "/avdelinger/:departmentId/rediger-ansatte",
    exact: true,
    sidebar: () => <Sidebar />,
    main: () => <EmployeesBulk />,
    protected: true,
    breadcrumb: "Ansatte",
  },
  {
    path: "/avdelinger/:departmentId/avdelingsoppsett",
    exact: true,
    sidebar: () => <Sidebar />,
    main: () => <DepartmentSettings />,
    protected: true,
    breadcrumb: "Avdelingsoppsett",
  },
  {
    path: "/avdelinger/:departmentId",
    exact: true,
    sidebar: () => <Sidebar />,
    main: () => <Department />,
    protected: true,
    breadcrumb: "Avdeling",
  },
  {
    path: "/test",
    exact: true,
    protected: false,
    test: true,
  },
  {
    path: "/",
    exact: true,
    sidebar: () => <PublicSidebar />,
    main: () => <Departments />,
    protected: false,
    breadcrumb: "Hjem",
  },
];

export { routes };
