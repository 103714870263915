import React from "react";
// import * as dynomite from "../lib/dynomite";

export default React.createContext();
export const loadDynomite = async () => {
  try {
    const dynomite = await import("../lib/dynomite");
    await dynomite.$init;
    return dynomite;
  } catch (e) {
    console.error(e);
    return;
  }
};
