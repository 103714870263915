
import React, { useEffect, useState } from "react";
import { DepartmentCard, HomeContent } from "../components";
import { Button, Modal, Spinner } from "rendition";
import { dynoapeAPI } from "../api/dynoape";
import { Header } from "../components";
import { validCharactersRegex } from "../lib/common";
import { defaultDepartment } from "../lib/defaults";
import {SolidInput} from "../lib/styled-components";

const DepartmentList = ({ departments }) => {
  const listDepartments = departments?.map((department) => <DepartmentCard key={department.id} department={department} />);
  return (<div>{listDepartments}</div>);
}

const Departments = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [displayHome, setDisplayHome] = useState(false);
  const [showAddDepartmentModal, setShowModal] = useState(false);
  const [showInvalidNameError, setShowInvalidNameError] = useState({ "show": false, "msg": "" });
  const [ownedDepartments, setOwnedDepartments] = useState([]);
  const [sharedDepartments, setSharedDepartments] = useState([]);
  const [countryCodeRules, setCountryCodeRules] = useState([]);
  const [department, setDepartment] = useState(defaultDepartment);

  const closeEditNameModal = () => {
    setShowInvalidNameError({ "show": false, "msg": "" });
    setDepartment(defaultDepartment);
    setShowModal(false);
  }

  const getData = async () => {
    try {
      const [
        departmentsData,
        countryCodeRules
      ] = await Promise.all([
        dynoapeAPI.get(`/api/v1/departments`),
        dynoapeAPI.get(`/api/v1/countryrules`)
      ]);
      setOwnedDepartments(departmentsData.ownedDepartments);
      setSharedDepartments(departmentsData.externalDepartments);
      setCountryCodeRules(countryCodeRules);
      setDepartment({... department, countryRulesId: countryCodeRules[0].id})
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setDisplayHome(true);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const addDepartment = async () => {
    department.name = department.name.trim();
    if (department.name.length <= 0) {
      setShowInvalidNameError({ "show": true, "msg": "Avdelingsnavn er påkrevd" });
      return;
    }
    if (showInvalidNameError.show) {
      return;
    }

    const response = await dynoapeAPI.post(`/api/v1/department`, department);
    if (response)
      setOwnedDepartments(ownedDepartments.concat(response));

    closeEditNameModal();
  };

  if (isLoading) {
    return <></>
  }

  if (displayHome) {
    return <HomeContent />
  }

  var countryCodeOptions = [];
  countryCodeRules.map(c => countryCodeOptions.push(<option key={`opt-${c.id}`} value={c.id}>{c.countryCode}</option>));

  return (
    <div>
      <Header
        title="Avdelinger"
        withTooltip
        tooltipId="dep-header"
        tooltipContent="Du er nå i oversikten over de avdelinger du har tilgang til i Dynamon. Dersom ingen avdelinger er synlige kan du opprette en avdeling"
        style={{ width: "1170px", marginLeft: "auto", marginRight: "auto" }}>
      </Header>

      <div style={{
        width: "1170px",
        marginLeft: "auto",
        marginRight: "auto"
      }}>
        <div
          style={{
            display: "flex"
          }}
        >
          <h3 style={{ marginLeft: "10px" }}>Mine avdelinger</h3>
          <Button
            icon={<p>+</p>}
            primary
            ml={"auto"}
            mr={"20px"}
            mt={"15px"}
            onClick={() => setShowModal(true)}>Opprett ny avdeling</Button>
        </div>
        {isLoading ? <Spinner show /> : !ownedDepartments.length ?
          <div style={{ padding: "10px 20px 20px 10px" }}><h4>Ingen egne avdelinger funnet</h4></div>
          : <DepartmentList departments={ownedDepartments} />
        }
        <h3 style={{
          marginLeft: "10px",
        }}>Avdelinger som er delt med meg</h3>
        {isLoading ? <Spinner show /> : !sharedDepartments.length ?
          <div style={{ padding: "10px 20px 20px 10px" }}><h4>Ingen delte avdelinger funnet</h4></div>
          : <DepartmentList departments={sharedDepartments} />
        }
        {showAddDepartmentModal && (
          <Modal
            title="Opprett ny avdeling"
            cancel={() => {
              closeEditNameModal();
            }}
            cancelButtonProps={{
              children: 'Avbryt'
            }}
            done={() => {
              addDepartment();
            }}
            action="Legg til"
          >
            <SolidInput
              onChange={(e) => {
                let name = e.target.value;
                setDepartment({ ...department, name: name });

                if (name.length <= 0) {
                  setShowInvalidNameError({ "show": true, "msg": "Avdelingsnavn er påkrevd" });
                  return;
                }

                if (name.length > 40) {
                  setShowInvalidNameError({ "show": true, "msg": "Navn på avdeling kan ikke være lenger enn 40 tegn" });
                  return;
                }

                if (!validCharactersRegex().test(name)) {
                  setShowInvalidNameError({ "show": true, "msg": "Navn på avdeling kan ikke inneholde ugyldige tegn" });
                  return;
                }

                setShowInvalidNameError({ "show": false, "msg": "" });
              }}
              value={department.name}
              placeholder="Avdelingsnavn"
            />
            <p htmlFor={"countryCode"}>Landekode</p>
            <select
              id="countryCode"
              defaultValue={department.countryRulesId}
              onChange={(e) =>
                setDepartment({ ...department, countryRulesId: e.target.value })}
            >
              {countryCodeOptions}
            </select>
            {showInvalidNameError.show && (
              <p style={{ color: "#BF3D2B" }}>{showInvalidNameError.msg}</p>
            )}
          </Modal>
        )}
      </div>
    </div >
  );
};

export default Departments;
