/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from "react";
import { getCellProperty, getCharFromKeyCode, isAlphaNumericKey, keyCodes } from '@silevis/reactgrid';
import Select, { components } from 'react-select';

export const DropdownSelectCellTemplate = {
  getCompatibleCell(uncertainCell) {
    let selectedValue = undefined;
    try {
      selectedValue = getCellProperty(uncertainCell, 'selectedValue', 'string');
    } catch {
      selectedValue = undefined;
    }
    const values = getCellProperty(uncertainCell, 'values', 'object');

    let value = selectedValue ? parseFloat(selectedValue) : NaN;

    let isDisabled = true;
    try {
      isDisabled = getCellProperty(uncertainCell, 'isDisabled', 'boolean');
    } catch {
      isDisabled = false;
    }
    let inputValue = undefined;
    try {
      inputValue = getCellProperty(uncertainCell, 'inputValue', 'string');
    } catch {
      inputValue = undefined;
    }
    let isOpen = false;
    try {
      isOpen = getCellProperty(uncertainCell, 'isOpen', 'boolean');
    } catch {
      isOpen = false;
    }
    let textReturn = '';
    try {
      textReturn = getCellProperty(uncertainCell, 'text', 'string');
    } catch {
      textReturn = '';
    }
    const text = selectedValue || textReturn;
    return { ...uncertainCell, selectedValue, text, value, values, isDisabled, isOpen, inputValue };
  },

  update(cell, cellToMerge) {
    return this.getCompatibleCell({ ...cell, text: cellToMerge.text, selectedValue: cellToMerge.text, isOpen: cellToMerge.isOpen, inputValue: cellToMerge.inputValue });
  },

  getClassName(cell) {
    const isOpen = cell.isOpen ? 'open' : 'closed';
    return `${cell.className ? cell.className : ''}${isOpen}`;
  },

  handleKeyDown(cell, keyCode, ctrl, shift, alt) {
    if ((keyCode === keyCodes.SPACE || keyCode === keyCodes.ENTER) && !shift) {
      return { cell: this.getCompatibleCell({ ...cell, isOpen: !cell.isOpen }), enableEditMode: false };
    }
    const char = getCharFromKeyCode(keyCode, shift);
    if (!ctrl && !alt && isAlphaNumericKey(keyCode))
      return { cell: this.getCompatibleCell({ ...cell, inputValue: shift ? char : char.toLowerCase(), isOpen: !cell.isOpen }), enableEditMode: false }
    return { cell, enableEditMode: false };
  },

  render(
    cell,
    isInEditMode,
    onCellChanged
  ) {
    return (
      <DropdownInput onCellChanged={(cell) => onCellChanged(this.getCompatibleCell(cell), true)} cell={cell} />
    );
  }
}

export const selectAllFromDropdown= {id: "constant-id-7ade1d8a-b2a8-11ed-afa1-0242ac120002", value: "Velg/fjern alle", label: "Velg/fjern alle"}

const DropdownInput = ({ onCellChanged, cell }) => {
  const wrapperRef = useRef(null);
  const selectRef = useRef(null);

  function useOutsideAlerter(ref, setIsOpen) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const [inputValue, setInputValue] = useState(cell.inputValue);
  const [isOpen, setIsOpen] = useState(false);
  useOutsideAlerter(wrapperRef, setIsOpen);

  let defaultValue = cell.values.find((val) => val.value === cell.selectedValue);
  if (cell.isMulti) {
    defaultValue = cell.values.filter(sv => cell.selectedValue?.split(", ").includes(sv.value));
  }

  useEffect(() => {
    if (cell.isOpen && selectRef.current) {
      selectRef.current.focus();
      setInputValue(cell.inputValue);
    }
  }, [cell.isOpen, cell.inputValue]);

  function updateCell(values) {
    if (cell.isMulti) {
      const vals = values.map(val => val.value).join(", ")
      if (cell.values.length === 1) {
        setIsOpen(false);
        return onCellChanged({...cell, selectedValue: vals, text: vals, inputValue: undefined});
      }
      return onCellChanged({...cell, selectedValue: vals, text: vals, inputValue: undefined});
    }

    return onCellChanged({ ...cell, selectedValue: values.value, isOpen: false, inputValue: undefined });
  }

  function getValue() {
    if (!cell.selectedValue)
      return []

    if (cell.isMulti && cell.selectedValue)
      return cell.selectedValue.length > 0 ? cell.selectedValue?.split(", ").map(val => { if (cell.values.map((option => { return option.value })).includes(val)) return { label: val, value: val } }) : [];

    return { label: cell.selectedValue, value: cell.selectedValue };
  }

  return <div ref={wrapperRef}
    style={{ width: '100%' }}
    onMouseUp={(e) => {
      if (e.target.localName === "svg" || e.target.localName === "path") {
        setIsOpen(!isOpen);
      } else {
        setIsOpen(true) } }}
  >
    <Select
      {...(cell.inputValue && {
        inputValue,
        defaultInputValue: inputValue,
        onInputChange: e => setInputValue(e),
      })}
      isSearchable={false}
      isMulti={cell.isMulti ? true : false}
      ref={selectRef}
      {...(cell.isOpen !== undefined && { menuIsOpen: cell.isOpen && isOpen })}
      //onMenuClose={() => setIsOpen(false)}
      onMenuOpen={() => onCellChanged({ ...cell, isOpen: true })}
      onChange={(e) => {
        let toUpdate = e;
        if(cell.isMulti) {
          let selectAll = e.filter(v => v.id && v.id === selectAllFromDropdown.id).length > 0;
          let allSelected = cell.values.length === e.length;
          toUpdate = selectAll && !allSelected ? cell.values.filter(v => v.id !== selectAllFromDropdown.id) : (allSelected ? [] : e);
        }
        updateCell(toUpdate);
      }}
      blurInputOnSelect={true}
      defaultValue={defaultValue}
      value={getValue()}
      isDisabled={cell.isDisabled}
      options={cell.values}
      onKeyDown={e => e.stopPropagation()}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      placeholder=""
      components={{
        Option: CustomOption,
        ValueContainer: cell.showAll === true ? ValueContainerWithoutCropping : ValueContainer
      }}
      menuPlacement="auto"
      menuPosition="fixed"
      menuPortalTarget={document.querySelector(".reactgrid")}
      styles={{
        container: (provided) => ({
          ...provided,
          width: '100%',
          height: '100%',
        }),
        control: (provided) => ({
          ...provided,
          border: 'none',
          borderColor: 'transparent',
          minHeight: '25px',
          background: 'transparent',
          boxShadow: 'none'
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          paddingTop: '0px',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          padding: '0px 4px',
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'inherit'
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          marginTop: '4px',
          marginBottom: '4px',
        }),
        input: (provided) => ({
          ...provided,
          padding: 0,
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: '0 8px',
        }),
        menu: (provided) => ({
          ...provided
        }),
      }}
    />
  </div >
}

const CustomOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const ValueContainer = ({ children, ...props }) => {
  let [values, input] = children;
  if (Array.isArray(values)) {
    const val = (i) => values[i].props.children;
    const { length } = values;

    switch (length) {
      case 1:
        values = `${val(0)}`;
        break;
      case 2:
        values = `${val(0)}, ${val(1)}`;
        break;
      case 3:
        values = `${val(0)}, ${val(1)}, ${val(2)}`;
        break;
      case 4:
        values = `${val(0)}, ${val(1)}, ${val(2)}, ${val(3)}`;
        break;
      case 5:
        values = `${val(0)}, ${val(1)}, ${val(2)}, ${val(3)}, ${val(4)}`;
        break;
      case 6:
        values = `${val(0)}, ${val(1)}, ${val(2)}, ${val(3)}, ${val(4)}, ${val(5)}`;
        break;
      default:
        values = `${val(0)}, ${val(1)}, ${val(2)}, ${val(3)}, ${val(4)}, ${val(5)} + ${length - 6} andre`;
        break;
    }
  }
  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

const ValueContainerWithoutCropping = ({ children, ...props }) => {
  let [values, input] = children;
  return (
      <components.ValueContainer {...props}>
        {Array.isArray(values) ? values.map(val => val.props.children).join(", ") : values}
        {input}
      </components.ValueContainer>
  );
};