import React from "react";
import styled from 'styled-components';
import AuthNav from "./auth-nav";

const DynamonHeader = () => {
  return <Header>
    <Logo src="/assets/logos/dynamon.svg" />
    <IconSet>
      <AuthNav />
    </IconSet>
  </Header>
};

const Header = styled.div`
  height: 80px;
  left: 0px;
  right: 0px;
  top: 0px;
  border-bottom: 6px solid #EDEDED;
  background-color: #fff;
`;

const IconSet = styled.div`
position: absolute;
right: 10px;
top: 30px;
`;

const Logo = styled.img`
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding-top: 30px;
`;

export default DynamonHeader;
