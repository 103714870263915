import React from "react";
import MainNav from "./main-nav";
import styled from "styled-components";

const Sidebar = () => {
  return (
    <Bar>
      <MainNav />
    </Bar>
  );
};

const Bar = styled.div`
  display: flex;
  flex-direction: column;
  background: #26565B;
  position: absolute;
  top: -86px;
  padding-bottom: 86px;
  z-index: 1;
  min-height: 1000px;
  height: 100%;
`;

export default Sidebar;