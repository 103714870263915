//TODO - refactor button functionality!!!!
import React, { useState } from "react";
import {Button} from "rendition";
import styled from "styled-components";
import { VariableRules } from "./variable-rules";
import { LocalRules } from "./local-rules";
import { TaskShiftSums } from "./task-shifts-sum"
import {InputWithValidation, TextArea} from "../lib/styled-components";
import Tooltip from "../lib/tooltip";
import {validCharactersRegex} from "../lib/common";
import ErrorMessage from "./error-message";

const TaskSettings = ({
  register,
  task,
  control,
  watch,
  errors,
  shifts,
  setValue,
}) => {
  const [step, setStep] = useState(0);

  if (step === 1) {
    return (
      <TaskTab>
        <RuleCard>
          <Button mr="10px" onClick={() => setStep(0)}>Regler</Button>
        </RuleCard>
      </TaskTab>
    );
  }

  return (
    <TaskTab>
        <RuleCard>
            <h3>Kommentar</h3>
            <div>
                <InputRow>
                    <InputDivider>
                        <p><label htmlFor={"comment"}>Kommentar</label></p>
                        <InputWithValidation>
                            <TextArea
                                className="TextArea"
                                style={{width: "1270px", fontFamily: "Montserrat Alternates"}}
                                maxLength={400}
                                multiple
                                id={`comment`}
                                key={`comment`}
                                name="comment"
                                defaultValue={task.comment}
                                {...register(`comment`, { required: false, pattern: validCharactersRegex() })}
                                data-for="comment"
                                data-tip="Her kan du skrive inn notater som gjelder for bemanningsplanen"
                            />
                            {ErrorMessage(errors?.comment, 'pattern', 'Du har ugyldige tegn i kommentarfeltet')}
                        </InputWithValidation>
                        <Tooltip id="comment" />
                    </InputDivider>
                </InputRow>
            </div>
        </RuleCard>

      <RuleCard style={{ borderTopLeftRadius: "0px" }}>
        <h3>Innstillinger</h3>
        <VariableRules InputRow={InputRow} InputDivider={InputDivider} register={register} config={task.config} control={control} errors={errors} watch={watch} />
        <h3>Antall vakter</h3>
        <TaskShiftSums InputDivider={InputDivider} register={register} config={task.config} control={control} errors={errors} watch={watch} shifts={shifts} />
      </RuleCard>

      <RuleCard>
        <h3>Lokale regler</h3>
        <LocalRules InputRow={InputRow} InputDivider={InputDivider} register={register} config={task.config} setValue={setValue} errors={errors}/>
      </RuleCard>
    </TaskTab>
  );
};

export default TaskSettings;

const RuleCard = styled.div`
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  margin-bottom: 25px;
  padding: 25px;
  width: 1350px;
  button {
    margin-right: 10px; 
  }
`;

const TaskTab = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 2em;
  background-color: #E2ECED;
  padding-left: 20px;
  padding-bottom: 10px;
`

const InputDivider = styled.div`
  min-width: 300px;
  margin-bottom: 10px;
`
