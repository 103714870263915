import React from "react";
import { Box, Flex, Input, Txt, Button, ButtonGroup } from "rendition";
import Select from "react-select";
import Tooltip from "../../lib/tooltip";
import { DateSelectionInput } from "../date/single-date-input";

/**
 * Component to show a standard filtering inputs for employees.
 * 
 * Component has the ability to show/hide the parts of the filtering mechanism
 * based on the available parameters. Each parameter is of an object
 * or undefined type. In case of undefined type, the component will hide
 * the part of the filtering mechanism. In case of object type, the component
 * assumes it will have a value parameter and onChange callback.
 * 
 * Component itself has no state since it has to be able to communicate
 * the changes upwards (towards parent component).
 * @param {string} width - width of the component.
 * @param {Object} name - filter by text input for employee name. Object has to
 *      have string property "value" and callback "onChange" inside.
 *      Additionaly, we can controll width (by specifying it). It has to be
 *      a string.
 * @param {Object} positions - filter by positions. Object has to have array
 *      property "value" (each element being an object with "id" and "name")
 *      and callback "onChange" inside. Positions can be multi-selectable.
 *      Adding "isMulti" will make the component multi-choice.
 *      Additionaly, we can controll width (by specifying it). It has to be
 *      a string.
 * @param {Object} traits - filter by traits. Object has to have array property
 *      "value" (each element being an object with "id" and "name")
 *      and callback "onChange" inside.
 *      Additionaly, we can controll width (by specifying it). It has to be
 *      a string.
 * @param {Object} dateRange - filter by date range. Object has to have
 *      properties "startDate" and "endDate" (string) and callback "onChange"
 *      inside.
 * @param {Object} kinds - filter by kinds. Object has to have array property
 *      "value" (each element being a string) and callback "onChange" inside.
 *      Additionaly, the component opens up to controll the tooltip text, by
 *      specifying "tooltip" property as a string.
 * @param {Object} daySegments - filter by day segments. Object has to have
 *      array property "value" (each element being a string) and callback
 *      "onChange" inside. Additionaly, the component opens up to controll
 *      the tooltip text, by specifying "tooltip" property as a string.
 *      
 * @param {Array} customComponents - array of custom components added to the end of
 *      the filter component.
 */
export default ({
    width,
    name,
    positions,
    traits,
    dateRange,
    kinds,
    daySegments,
    customComponents
}) => {
    return <Flex
        justifyContent="space-between"
        style={{
            ...width ? { width: width } : null,
            gap: '0.5em'
        }}
    >
        {name && <Box style={{flex: `2 1 ${name.width ? name.width : '160px'}`}}>
            <Txt style={{ marginBottom: '1em' }}>Søk etter ansatte</Txt>
            <Input
                style={{borderColor: '#ccc'}}
                value={name.value}
                placeholder=""
                onChange={e => name.onChange(e.target.value)}
            />
        </Box>}
        {positions && <Box
            style={{flex: `1 1 ${positions.width ? positions.width : '160px'}`}}
        >
            <Txt style={{ marginBottom: '1em' }}>Filter på stilling</Txt>
            <Select
                placeholder=""
                isClearable={true}
                isMulti={positions.isMulti}
                options={positions.value.map(p => ({
                    label: p.name,
                    value: p.id
                }))}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: "#306970",
                        primary75: "#8ea1a4",
                        primary50: "#bec7c9",
                        primary25: "#dfe8e9",
                    }
                })}
                style={{width: positions.width ? positions.width : '160px'}}
                styles={{
                    control: (provided, state) => ({
                        ...provided,
                        width: positions.width ? positions.width : '160px',
                        boxShadow: state.isFocused ? '0 0 2px 2px #306970' : null
                    }),
                    menu: (provided) => ({
                        ...provided,
                        width: positions.width ? positions.width : '160px',
                        zIndex: 200,
                    })
                }}
                onChange={(pos) => positions.onChange(positions.isMulti
                    ? pos.map(p => p.value)
                    : pos ? pos.value : undefined
                )}
            />
        </Box>}
        {traits && <Box style={{flex: `1 1 ${traits.width ? traits.width : '160px'}`}}>
            <Txt style={{ marginBottom: '1em' }}>Filter på kompetanse</Txt>
            <Select
                placeholder=""
                isClearable={true}
                isMulti={true}
                options={traits.value.map(p => ({
                    label: p.name,
                    value: p.id
                }))}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: "#306970",
                        primary75: "#8ea1a4",
                        primary50: "#bec7c9",
                        primary25: "#dfe8e9",
                    }
                })}
                style={{width: traits.width ? traits.width : '160px'}}
                styles={{
                    control: (provided, state) => ({
                        ...provided,
                        boxShadow: state.isFocused ? '0 0 2px 2px #306970' : null,
                        width: traits.width ? traits.width : '160px'
                    }),
                    menu: (provided) => ({
                        ...provided,
                        width: traits.width ? traits.width : '160px',
                        zIndex: 200,
                    })
                }}
                onChange={(tts) => traits.onChange(tts.map(t => t.value))}
            />
        </Box>}
        {dateRange && <Box
            style={{flex: "0 0 150px"}}
            data-for="date-range-from"
            data-tip="..."
        >
            <Txt style={{ marginBottom: "1em" }}>Filtrer etter datoer</Txt>
            <DateSelectionInput
                value={dateRange.startDate}
                onChange={date => dateRange.onChangeStart(date)}
                maxDate={new Date(dateRange.endDate)}
                text="Fra"
                width="155px"
            />
            <Tooltip id="date-range-from" />
        </Box>}
        {dateRange && <Box
            style={{flex: "0 0 150px"}}
            data-for="date-range-until"
            data-tip="..."
        >
            <Txt style={{ marginBottom: "1em" }}>&nbsp;</Txt>
            <DateSelectionInput
                value={dateRange.endDate}
                onChange={date => dateRange.onChangeEnd(date)}
                minDate={new Date(dateRange.startDate)}
                text="Til"
                width="155px"
            />
            <Tooltip id="date-range-until" />
        </Box>}
        {kinds && <Box style={{flex: '0 0 140px'}}>
            <Txt style={{ marginBottom: '1em' }}
                data-for='patterns-kind'
                data-tip={kinds.tooltip ? kinds.tooltip : 'Knappene aktiverer \
                    og deaktiverer visning av ferie eller annet fravær i \
                    kalenderen under.'}
            >Se ferie/fravær/permisjon</Txt>
            <Tooltip id='patterns-kind' />
            <ButtonGroup>
                <Button
                    style={{paddingRight: '10px'}}
                    primary={kinds.value.includes('vacation')}
                    onClick={() => kinds.onChange('vacation')}
                >Ferie</Button>
                <Button
                    style={{paddingRight: '10px', paddingLeft: '10px'}}
                    primary={kinds.value.includes('paid-leave')}
                    onClick={() => kinds.onChange("paid-leave")}
                >Permisjon</Button>
                <Button
                    style={{paddingLeft: '10px'}}
                    primary={kinds.value.includes('not-working')}
                    onClick={() => kinds.onChange('not-working')}
                >Fravær</Button>
            </ButtonGroup>
        </Box>}
        {daySegments && <Box style={{flex: '0 0 250px'}}>
            <Txt style={{ marginBottom: '1em' }}
                data-for='patterns-day-segments'
                data-tip={daySegments.tooltip ? daySegments.tooltip : 'Knappene \
                    under aktiverer eller deaktiverer visning av vaktyper som \
                    har fravær i kalenderen under.'}
            >Se vakter</Txt>
            <Tooltip id='patterns-day-segments' />
            <ButtonGroup>
                <Button
                    style={{paddingRight: '18px'}}
                    primary={daySegments.value.includes('d')}
                    onClick={() => daySegments.onChange('d')}
                >Dag</Button>
                <Button
                    style={{padding: '4px 16px'}}
                    primary={daySegments.value.includes('a')}
                    onClick={() => daySegments.onChange('a')}
                >Kveld</Button>
                <Button
                    style={{padding: '4px 16px'}}
                    primary={daySegments.value.includes('n')}
                    onClick={() => daySegments.onChange('n')}
                >Natt</Button>
                <Button
                    style={{paddingLeft: '18px'}}
                    primary={daySegments.value.includes('l')}
                    onClick={() => daySegments.onChange('l')}
                >Lang</Button>
            </ButtonGroup>
        </Box>}
        {customComponents && <>{customComponents}</>}
    </Flex>
}