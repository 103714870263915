import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "rendition";
import { dynoapeAPI, getSortedCompetences } from "../api/dynoape";
import { useParams } from "react-router-dom";
import { Table } from "rendition";
import { validCharactersRegex } from "../lib/common";
import { SolidInput } from "../lib/styled-components";

const CompetencesTable = () => {
  const { departmentId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [showCompetenceModal, setShowModal] = useState({ show: false, id: "" });
  const [showDeleteModal, setShowDeleteModal] = useState({
    show: false,
    id: "",
  });
  const [showInvalidNameError, setShowInvalidNameError] = useState({
    show: false,
    msg: "",
  });
  const [competenceName, setNameInput] = useState("");
  const [competences, setCompetences] = useState([]);

  const columns = [
    {
      field: "name",
      label: (
        <span style={{ width: "500px", display: "block" }}>
          Spesialkompetanse
        </span>
      ),
      sortable: false,
    },
    {
      field: "id",
      label: " ",
      render: function displayColumn(data, row) {
        return (
          <Button
            primary
            onClick={() => {
              setNameInput(row.name);
              setShowModal({ show: true, id: data });
            }}
            style={{ float: "right", width: "220px" }}
          >
            Endre
          </Button>
        );
      },
    },
    {
      field: "id",
      label: " ",
      render: function displayColumn(data) {
        return (
          <Button
            primary
            onClick={() => setShowDeleteModal({ show: true, id: data })}
            style={{ float: "right", width: "220px" }}
          >
            Slett
          </Button>
        );
      },
    },
  ];

  const closeModal = () => {
    setShowInvalidNameError({ show: false, msg: "" });
    setNameInput("");
    setShowModal({ show: false, id: "" });
  };

  useEffect(() => {
    const setSortedCompetences = async () => {
      let comp = await getSortedCompetences(departmentId);
      setCompetences(comp);
      setIsLoading(false);
    };
    setSortedCompetences();
  }, []);

  const addCompetence = async () => {
    if (competences.length >= 99) {
      setShowInvalidNameError({
        show: true,
        msg: "Kan ikke ha flere enn 99 aktive spesialkompetanser",
      });
      return;
    }
    if (competenceName.trim().length <= 0) {
      setShowInvalidNameError({
        show: true,
        msg: "Navn på spesialkompetanse er påkrevd",
      });
      return;
    }
    if (showInvalidNameError.show) {
      return;
    }

    await dynoapeAPI.post(`/api/v1/department/${departmentId}/trait`, {
      name: competenceName.trim(),
    });

    let comp = await getSortedCompetences(departmentId);
    setCompetences(comp);
    closeModal();
  };

  const editCompetence = async (id) => {
    if (competenceName.trim().length <= 0) {
      setShowInvalidNameError({
        show: true,
        msg: "Navn på spesialkompetanse er påkrevd",
      });
      return;
    }
    if (showInvalidNameError.show) {
      return;
    }
    await dynoapeAPI.put(`/api/v1/department/${departmentId}/trait/${id}`, {
      name: competenceName.trim(),
    });

    let comp = await getSortedCompetences(departmentId);
    setCompetences(comp);
    closeModal();
  };

  const deleteCompetence = async (id) => {
    try {
      await dynoapeAPI.delete(`/api/v1/department/${departmentId}/trait/${id}`);
      setCompetences(
        competences.filter((p) => {
          return p.id !== id;
        })
      );
    } catch (error) {
      console.error(error);
    }
    setShowDeleteModal({ show: false, id: "" });
  };

  return (
    <div>
      <Button
        icon={<p>+</p>}
        ml="auto"
        mb="10px"
        onClick={() => setShowModal({ show: true, id: "" })}
        primary
      >
        Legg til spesialkompetanse
      </Button>
      {isLoading ? (
        <Spinner show />
      ) : !competences?.length ? (
        <div style={{ background: "white", padding: "10px 20px 20px 10px" }}>
          <h4>Ingen spesialkompetanser funnet</h4>
        </div>
      ) : (
        <Table columns={columns} data={competences} rowKey="id" />
      )}
      {showCompetenceModal.show && (
        <Modal
          title={
            showCompetenceModal.id !== ""
              ? "Endre spesialkompetanse"
              : "Legg til ny spesialkompetanse"
          }
          cancel={() => {
            closeModal();
          }}
          cancelButtonProps={{
            children: "Avbryt",
          }}
          done={() => {
            if (competenceName.trim().length <= 0) {
              setShowInvalidNameError({
                show: true,
                msg: "Et navn er påkrevd, skriv inn et navn på spesialkompetanser",
              });
              return;
            }
            if (showInvalidNameError.show) {
              return;
            }

            if (showCompetenceModal.id !== "")
              return editCompetence(showCompetenceModal.id);

            addCompetence();
          }}
          action={showCompetenceModal.id !== "" ? "Lagre" : "Legg til"}
        >
          <SolidInput
            onChange={(e) => {
              let name = e.target.value.trim();
              setNameInput(name);

              if (name.length <= 0) {
                setShowInvalidNameError({
                  show: true,
                  msg: "Et navn er påkrevd, skriv inn et navn på spesialkompetansen",
                });
                return;
              }

              if (
                competences.filter(
                  (p) =>
                    p.name.toLowerCase() === name.toLowerCase() &&
                    p.id !== showCompetenceModal.id
                ).length > 0
              ) {
                setShowInvalidNameError({
                  show: true,
                  msg: "Det finnes allerede en spesialkompetanse med det navnet",
                });
                return;
              }

              if (name.length > 30) {
                setShowInvalidNameError({
                  show: true,
                  msg: "Navn på spesialkompetansen kan ikke være lenger enn 30 tegn",
                });
                return;
              }

              if (!validCharactersRegex().test(name)) {
                setShowInvalidNameError({
                  show: true,
                  msg: "Navn på spesialkompetanse kan ikke inneholde ugyldige tegn",
                });
                return;
              }

              setShowInvalidNameError({ show: false, msg: "" });
            }}
            defaultValue={competenceName}
            placeholder="Navn"
          />
          {showInvalidNameError.show && (
            <p style={{ color: "#BF3D2B" }}>{showInvalidNameError.msg}</p>
          )}
        </Modal>
      )}
      {showDeleteModal.show && (
        <Modal
          title="Slett spesialkompetanse"
          cancel={() => {
            setShowDeleteModal({ show: false, id: "" });
          }}
          done={() => {
            deleteCompetence(showDeleteModal.id);
          }}
          action="Slett spesialkompetanse"
          cancelButtonProps={{
            children: "Avbryt",
          }}
        >
          <p>Er du sikker på at du vil slette denne spesialkompetansen?</p>
        </Modal>
      )}
    </div>
  );
};

export default CompetencesTable;
