
import React, { forwardRef } from "react";
import styled from "styled-components";
import { InputWithValidation, NumberInput } from "../lib/styled-components";
import ErrorMessage from "./error-message";
import { noDecimalRegex, addWeeks } from "../lib/common";
import Tooltip from "../lib/tooltip";
import moment from "moment";
import { Controller } from "react-hook-form";
import Icon from "../lib/icon";
import DatePicker, { registerLocale } from "react-datepicker";
import nb from "date-fns/locale/nb";
registerLocale("nb", nb);

import "react-datepicker/dist/react-datepicker.css";

const DateButtonInput = forwardRef(({ value, onClick }, ref) => (
  <DateButton className={"DateButton"} onClick={onClick} ref={ref}>
    {value !== "1.1.1970" ? new Date(value).toLocaleDateString("nb-NO") : "Velg dato"}
    <Icon name="date" f="right" />
  </DateButton>
));

export const VariableRules = ({
  register,
  config,
  watch,
  control,
  errors,
  InputRow,
  InputDivider
}) => {
  return (
    <div>
      <InputRow>
        <InputDivider>
          <p><label htmlFor={"nrOfWeeks"}>Hvor mange uker skal turnusen gå over</label></p>
          <InputWithValidation>
            <NumberInput
              id={`config.nrOfWeeks`}
              key={`config.nrOfWeeks`}
              placeholder="1 til 52"
              type="number"
              width="80px"
              name="nrOfWeeks"
              defaultValue={config.nrOfWeeks}
              {...register(`config.nrOfWeeks`, { required: true, valueAsNumber: true, min: 1, max: 52, pattern: noDecimalRegex() })}
              data-for="nrOfWeeks"
              data-tip="Her angir du antall uker som turnusen skal genereres for"
            />
            {ErrorMessage(errors?.config?.nrOfWeeks, 'required', 'Uker er påkrevd')}
            {ErrorMessage(errors?.config?.nrOfWeeks, 'pattern', 'Desimaltall er ikke tillatt')}
            {ErrorMessage(errors?.config?.nrOfWeeks, 'min', 'Uker kan ikke være mindre enn 1')}
            {ErrorMessage(errors?.config?.nrOfWeeks, 'max', 'Uker kan ikke være mer enn 52')}
          </InputWithValidation>
          <Tooltip id="nrOfWeeks" />
        </InputDivider>
        <InputDivider>
          <p><label htmlFor={"startDate"}>Første dag i turnus</label></p>
          <Tooltip id="start-date" />
          <div style={{ display: "flex", flexFlow: "wrap" }} data-for="start-date" data-tip="Sett dato for når turnusen skal starte">
            <Controller
              name="enabled"
              control={control}
              defaultValue={new Date(config.startDate)}
              {...register(`config.startDate`, {
                required: false, validate: (e) => {
                  return moment(e).day() === 1 || e === null;
                }
              })}
              render={({ field }) => <DatePicker
                showWeekNumbers
                selected={new Date(field.value)}
                locale="nb"
                dateFormat="d.M.yyyy"
                filterDate={(date) => date.getDay() === 1}
                customInput={<DateButtonInput />}
                {...field}
                onChange={date => {
                  if (!date) {
                    field.onChange(undefined);
                    return;
                  }

                  // occasionally DatePicker will give us UTC dates, and occasionally
                  // it will give locale dates. Convert all dates to UTC to safely
                  // convert to ISO string
                  const utc = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
                  field.onChange(utc.toISOString().split("T")[0])
                }}
                openToDate={field.value ? new Date(field.value) : new Date()}
              />}
            />
            {ErrorMessage(errors?.config?.startDate, 'required', 'Dato er påkrevd')}
            {ErrorMessage(errors?.config?.startDate, 'validate', 'Første dag må være en mandag')}
          </div>
        </InputDivider>

        {watch("config.startDate") && <InputDivider>
          <p><label>Turnus genereres</label></p>
          <div><p>fra <b>{new Date(watch("config.startDate")).toLocaleDateString("nb-NO")}</b> til og med <b>{addWeeks(watch("config.nrOfWeeks"), new Date(watch("config.startDate"))).toLocaleDateString("nb-NO")}</b></p></div>
        </InputDivider>
        }
      </InputRow>
    </div >
  );
};

const DateButton = styled.div`
  background-color: white;
  border: 1px solid #E5E7f0;
  width: 120px;
  padding: 8px;
  cursor: pointer;
`;