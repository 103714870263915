import React, {useEffect, useState} from "react";
import Select from "react-select";
import {Alert, Button, Card, Modal, Spinner} from "rendition";
import {dynoapeAPI} from "../api/dynoape";
import {
    AvatarCardEmployeePosition,
    CardRow,
    InputCard,
} from "../lib/styled-components";
import Icon from "../lib/icon";
import uniqolor from "uniqolor";
import styled from "styled-components";
import {useParams} from "react-router-dom";
import {Header} from "./index";
import {BackButton} from "./buttons";
import {FeatureToggles} from "../lib/feature-toggles";


const OrgEmployeePage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { departmentId, employeeId } = useParams();
    const [departments, setDepartments] = useState([]);
    const [employee, setEmployee] = useState();

    const [selectedDepartment, setSelectedDepartment] = useState();
    const [employeesToSelect, setEmployeesToSelect] = useState();
    const [selectedEmployee, setSelectedEmployee] = useState();
    const [orgEmployee, setOrgEmployee] = useState();
    const [departmentsEligibleForEmployeeLinking, setDepartmentsEligibleForEmployeeLinking] = useState();
    const [showRemoveEmployeeFromOrgEmplModal, setShowRemoveEmployeeFromOrgEmplModal] = useState({show: false, employeeId: null});

    const getEmployeesInDepartment = async (depId) => {
        setEmployeesToSelect(await dynoapeAPI.get(`/api/v1/department/${depId}/employees`))
    }

    const getData = async () => {
        const [
            departmentsData,
            employeeData,
        ] = await Promise.all([
            dynoapeAPI.get(`/api/v1/departments`),
            dynoapeAPI.get(`/api/v1/department/${departmentId}/employee/${employeeId}`)
        ]);

        const deps = departmentsData.ownedDepartments.concat(departmentsData.externalDepartments)
        setDepartments(deps)
        setEmployee(employeeData);
        const pers = employeeData?.orgEmployeeId
            ? await dynoapeAPI.get(`/api/v1/org/org-employee/${employeeData.orgEmployeeId}`)
            : null;
        setDepartmentsEligibleForEmployeeLinking(deps.filter(d => !pers?.employees?.find(e => e.departmentId === d.id)).filter(d => d.allowedToUpdate))
        setOrgEmployee(pers);
        setIsLoading(false);
    };

    useEffect(() => {
        if(FeatureToggles.FEATURE_TOGGLE_ORG_EMPLOYEE_MODULE.isEnabled()) {
            getData();
        } else {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        setDepartmentsEligibleForEmployeeLinking(departments.filter(d => !orgEmployee?.employees?.map(e => e.departmentId).includes(d.id)).filter(d => d.allowedToUpdate));
    }, [orgEmployee])

    const addEmployeeToOrgEmpl = async () => {
        let resp = !orgEmployee
            ? await dynoapeAPI.post(`/api/v1/org/org-employee`, {ids: [selectedEmployee.value, employee.id]})
            : await dynoapeAPI.put(`/api/v1/org/org-employee/${orgEmployee.id}`, {ids: [selectedEmployee.value]});
        if(resp) {
            setOrgEmployee(resp);
        }
        setSelectedEmployee(null);
        setSelectedDepartment(null);
    }

    const removeEmployeeFromOrgEmpl = async (emplId) => {
        let pers = await dynoapeAPI.get(`/api/v1/org/org-employee/${orgEmployee.id}`);
        if(pers.employees.length <= 2) {
            await dynoapeAPI.delete(`/api/v1/org/org-employee/${orgEmployee.id}`)
            setOrgEmployee(null);
        } else {
            await dynoapeAPI.delete(`/api/v1/org/org-employee/${orgEmployee.id}/employee/${emplId}`);
            setOrgEmployee(await dynoapeAPI.get(`/api/v1/org/org-employee/${orgEmployee.id}`))
        }
    }

    const handleSelectDepartmentChange = (e) => {
        if (e) {
            setSelectedEmployee(null);
            setSelectedDepartment({value: e.value, label: e.label});
            getEmployeesInDepartment(e.value);
        } else {
            setSelectedEmployee(null);
            setSelectedDepartment(null);
        }
    };

    function errorElement (msg) {
        return (<div className="content"><p>{msg}.Ta kontakt med eier av avdelingen eller <a href="mailto: support@dynamon.no">support@dynamon.no</a></p></div>)
    }

    if(!FeatureToggles.FEATURE_TOGGLE_ORG_EMPLOYEE_MODULE.isEnabled()) {
        return errorElement("Denne modulen er ikke tilgjengelig");
    }

    if(isLoading || !departments || departments.length === 0 || !employee) {
        if(isLoading) {
            return (<div className="content"><p>Loading...</p></div>)
        } else if (!departments || departments.length === 0) {
            return errorElement("Ingen avdeling funnet. ");
        } else if (!employee) {
            return errorElement("Ansatt ikke funnet. ");
        }
    }

    return (
        <div>
            <Header title={"Knytt ansatt"}>
                <BackButton/>
            </Header>
            <div className="content">
                <CardRow>
                    <InputCard>
                        <CardRow>
                            <AvatarCard>
                                <Icon name="user-avatar" />
                                <EmployeeName>{employee.name}</EmployeeName>
                                {employee.position ?
                                    <AvatarCardEmployeePosition style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "70px", backgroundColor: employee.restPosition.color !== "000000" ? `#${employee.restPosition.color}` : uniqolor(employee.restPosition.id, { format: 'rgb' }).color, color: "white" }}>
                                        {employee.restPosition.name}
                                    </AvatarCardEmployeePosition>
                                    : <p>Mangler stilling</p>}
                                <AvatarCardFooter>
                                    Stillingsprosent: {employee.vacancyRate}%
                                </AvatarCardFooter>
                            </AvatarCard>
                        </CardRow>
                    </InputCard>
                    <InputCard>
                        <h3>Eksisterende</h3>
                        {orgEmployee?.employees?.filter(e => e.id !== employee.id).map(l => {
                            return (
                                <div key={l.id}>
                                    <div>
                                        <Icon pr="20px" name="user-avatar" />
                                        <div><Info> Ansatt: {l.name}</Info><Info style={{float: "right"}}>
                                            <Button onClick={() => setShowRemoveEmployeeFromOrgEmplModal({show: true, employeeId: l.id})} danger>Fjern</Button></Info></div>
                                        <Info> Avdeling: {l.departmentName}</Info>
                                        <Info> Stillingsprosent: {l.vacancyRate}%</Info>
                                        <Info> Stilling: {l.positionName}</Info>
                                    </div>
                                </div>
                            )
                        })}

                    </InputCard>
                </CardRow>
                <CardRow>
                    <InputCard>
                        <h3>Knytt den ansatte på tvers av avdelinger</h3>
                        <Info>Ansatte i forskjellige avdelinger kan knyttes til hverandre. Når dette gjøres, forteller man systemet
                            at ansatte i praksis er samme person som jobber i flere avdelinger. Det er ikke lov å knytte ansatte som
                            er i samme avdeling.</Info>
                        <Info>For å få lov til å knytte en ansatt i én avdeling til en ansatt i en annen avdeling, må den
                            innloggede brukeren være eier av begge avdelingene. </Info>
                    </InputCard>
                    <InputCard>
                        <div>
                            <div style={{marginTop: "10px"}}>
                                <label htmlFor={"selectDepForLinking"}>Velg avdeling</label>
                                <Select id="selectDepForLinking"
                                        data-for="selectDepForLinking"
                                        data-tip="Velg avdeling"
                                        onChange={handleSelectDepartmentChange}
                                        options={departmentsEligibleForEmployeeLinking.map(d => ({value: d.id, label: d.name}))}
                                        value={selectedDepartment}>
                                </Select>
                            </div>

                            {selectedDepartment && !employeesToSelect && <Spinner show />}
                            {selectedDepartment && employeesToSelect &&
                                <div style={{marginTop: "10px"}}>
                                    <label htmlFor={"selectEmplForLinking"}>Velg ansatt</label>
                                    <Select id="selectEmplForLinking"
                                            data-for="selectEmplForLinking"
                                            data-tip="Velg ansatt"
                                            onChange={(e) => {
                                                setSelectedEmployee({value: e.value, label: e.label});
                                            }}
                                            options={employeesToSelect.map(e => (
                                                {value: e.id, label: [e.name, e.restPosition?.name, e.vacancyRate + '%',].join(', ')})
                                            )}
                                            value={selectedEmployee}>
                                    </Select>
                                </div>
                            }
                        </div>
                        {selectedEmployee &&
                            <Button primary onClick={() => addEmployeeToOrgEmpl()}>Knytt ansatt</Button>}
                    </InputCard>
                </CardRow>
                {showRemoveEmployeeFromOrgEmplModal.show && (
                    <Modal
                        cancel={() => {
                            setShowRemoveEmployeeFromOrgEmplModal({show: false, employeeId: null});
                        }}
                        done={() => {
                            removeEmployeeFromOrgEmpl(showRemoveEmployeeFromOrgEmplModal.employeeId);
                            setShowRemoveEmployeeFromOrgEmplModal({show: false, employeeId: null});
                        }}
                        action="OK"
                        cancelButtonProps={{
                            children: 'Avbryt'
                        }}
                    >
                        <h3><Alert plaintext danger style={{ whiteSpace: "nowrap" }}>Vil du fjerne?</Alert></h3><p>Er du sikker på at du vil fjerne denne knytningen?</p>
                    </Modal>
                )}
            </div>
        </div>
    )
}

const AvatarCard = styled(Card)`
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: center;
  padding: 0px;
  padding-top: 10px;
  width: 430px;
`;
const EmployeeName = styled.h1`
  overflow-wrap: break-word;
  font-size: 26px;
  margin-top: 5px;
`;

const AvatarCardFooter = styled.div`
  position: absolute;
  bottom:0px;
  left: 0px;
  width: 100%;
  height: 35px;
  padding-top: 15px;
  background-color: #26565B3D;
  border-radius: 0px 0px 10px 10px;
`;
const Info = styled.div`
  font-family: Montserrat Alternates;
  font-size: 14px;
`;

export default OrgEmployeePage