import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Loading, DynamonHeader } from "./components";
import DynomiteContext, { loadDynomite } from "./components/dynomite";
import ProtectedRoute from "./auth/protected-route";
import { routes } from "./routes/routes";
import { ErrorHandler, addAccessTokenInterceptor } from "./api/dynoape";
import TestRoute from "./test/TestRoute";
import "./app.css";

const App = () => {
  const { isLoading } = useAuth0();
  const { getAccessTokenSilently } = useAuth0();
  const [dynomite, setDynomite] = useState();

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const fetchDynomite = async () => {
      setDynomite(await loadDynomite());
    }
    fetchDynomite();
  }, []);

  if (isLoading || !dynomite) {
    return <Loading />;
  }

  return (
    <div id="app">
      <DynomiteContext.Provider value={dynomite}>
        <DynamonHeader title={"departmentName"} />
        <div style={{ display: 'flex', minHeight: "calc(100vh - 86px)" }}>
          <div style={{ position: "relative" }}>
            {routes.map((route) => (
              <Route key={route.path} path={route.path} component={route.sidebar} exact={route.exact} />
            ))}
          </div>

          <div style={{ flex: 1 }}>
            <ErrorHandler>
              <Switch>
                {routes.map((route) => {
                  if (route.protected) {
                    return <ProtectedRoute key={route.path} path={route.path} component={route.main} exact={route.exact} />
                  } else if (route.test) {
                    return <TestRoute key={'/test'} path='/test' exact={true}/>
                  }
                  return <Route key={route.path} path={route.path} component={route.main} />
                })}
              </Switch>
            </ErrorHandler>
          </div>
        </div>
      </DynomiteContext.Provider>
    </div>
  );
};

export default App;
