import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Modal } from "rendition";

export function RouterPrompt(props) {
  const { when, onOK, onCancel, title } = props;

  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return "true";
      });
    } else {
      history.block(() => { });
    }

    return () => {
      history.block(() => { });
    };
  }, [history, when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(() => { });
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => { });
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <Modal
      title={title}
      cancel={handleCancel}
      cancelButtonProps={{
        children: 'Nei, bli på siden'
      }}
      done={handleOK}
      action="Ja, forlat siden"
    >
      <p style={{ color: "red" }}>Alle ulagrete endringer vil gå tapt</p>
    </Modal>
  ) : null;
}