import React, { useEffect, useState } from "react";
import { dynoapeAPI } from "../api/dynoape";
import { useParams, useHistory } from "react-router-dom";
import { Modal } from "rendition";
import { Header, EmployeeCard } from "../components";
import { CancelButton } from "../components/buttons";

const EditEmployee = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [employee, setEmployee] = useState({});
  const [employeeNames, setEmployeeNames] = useState([]);
  const { departmentId, employeeId } = useParams();
  const history = useHistory();

  const getData = async () => {
    const [
      employeeData,
      employeesData
    ] = await Promise.all([
      dynoapeAPI.get(`/api/v1/department/${departmentId}/employee/${employeeId}`),
      dynoapeAPI.get(`/api/v1/department/${departmentId}/employees`),
    ]);
    setEmployee(employeeData);
    setEmployeeNames(employeesData?.map(function (empl) {
      return { "id": empl.id, "name": empl.name };
    }));
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading || employee === undefined) {
    return (<div>
      <p>Loading...</p>
      {!isLoading && employee === undefined && (
        <Modal
          title="Feil"
          cancel={() => {

          }}
          cancelButtonProps={{
            style: { display: "none" }
          }}
          done={() => {
            history.push(`/avdelinger/${departmentId}/ansatte`);
          }}
          action="Gå tilbake"
        >
          <p>Denne ansatte tilhører en avdeling som ikke er delt med deg.</p> <p>Ta kontakt med eier av avdelingen eller <a href="mailto: support@dynamon.no">support@dynamon.no</a></p>
        </Modal>
      )}
    </div>
    )
  }

  return (
    <div>
      <Header title={"Ansatte"} showLicenseEmployeeInfo={true}>
        <CancelButton/>
      </Header>
      <div className="content">
        <EmployeeCard employee={employee} setEmployee={setEmployee} existingEmployeeNames={employeeNames} isNew={false}/>
      </div>
    </div>
  )

};

export default EditEmployee;
