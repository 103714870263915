import React from "react";
import { useParams, NavLink } from "react-router-dom";
import styled from "styled-components";
import Icon from "../lib/icon";
import Tooltip from "../lib/tooltip";
import { FeatureToggles } from "../lib/feature-toggles";

const MainNav = () => {
  const { departmentId } = useParams();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <StyledIcon>
        <Icon m="25px" name="dynamon_logo" />
      </StyledIcon>
      <StyledNavLink
        to=""
        exact
        className="nav-link"
        activeClassName="router-link-exact-active"
        data-for="departments-tooltip"
        data-tip="Ved å klikke på “Avdelinger” vil du alltid kunne gå ut til oversikten over avdelinger"
      >
        <Tooltip id="departments-tooltip" />
        <MenuIconContainer>
          <MenuIcon name="dashboard" />
        </MenuIconContainer>
        Avdelinger
      </StyledNavLink>
      <StyledNavLink
        to={`/avdelinger/${departmentId}/avdelingsoppsett`}
        exact
        className="nav-link"
        activeClassName="router-link-exact-active"
        data-for="settings-tooltip"
        data-tip="Her finner du oversikt over oppsettet for avdelingen"
      >
        <Tooltip id="settings-tooltip" />
        <MenuIconContainer>
          <MenuIcon name="settings-w" />
        </MenuIconContainer>
        Avd.oppsett
      </StyledNavLink>
      <StyledNavLink
        to={`/avdelinger/${departmentId}`}
        exact
        className="nav-link"
        activeClassName="router-link-exact-active"
        data-for="dashboard-tooltip"
        data-tip="Her finner du oversikt over alle funksjoner i Dynamon"
      >
        <Tooltip id="dashboard-tooltip" />
        <MenuIconContainer>
          <MenuIcon name="list" />
        </MenuIconContainer>
        Dashboard
      </StyledNavLink>

      <StyledNavLink
        to={`/avdelinger/${departmentId}/ansatte`}
        exact
        className="nav-link"
        activeClassName="router-link-exact-active"
        data-for="employees-tooltip"
        data-tip="Her finner du oversikt over alle ansatte i din avdeling og kan legge inn eller slette ansatte, samt spesifisere hvordan de skal arbeide"
      >
        <Tooltip id="employees-tooltip" />
        <MenuIconContainer>
          <MenuIcon name="user-2" />
        </MenuIconContainer>
        Ansatte
      </StyledNavLink>
      <StyledNavLink
        to={`/avdelinger/${departmentId}/bemanningsplaner`}
        exact
        className="nav-link"
        activeClassName="router-link-exact-active"
        data-for="tasks-tooltip"
        data-tip="Her finner du de bemanningsplaner du har laget, eller oppretter ny bemanningsplan for din avdeling"
      >
        <Tooltip id="tasks-tooltip" />
        <MenuIconContainer>
          <MenuIcon name="multiple-user" />
        </MenuIconContainer>
        Bemanning
      </StyledNavLink>
      {FeatureToggles.FEATURE_TOGGLE_RUNNER_MODULE.isEnabled() && (
        <StyledNavLink
          to={`/avdelinger/${departmentId}/kjør-bemanningsplaner`}
          exact
          className="nav-link"
          activeClassName="router-link-exact-active"
          data-for="plans-tooltip"
          data-tip="Her finner du oversikt over kjøreplanene dine"
        >
          <Tooltip id="plans-tooltip" />
          <MenuIconContainer>
            <MenuIcon name="world" />
          </MenuIconContainer>
          Kjøreplaner
        </StyledNavLink>
      )}
    </div>
  );
};

const MenuIcon = styled(Icon)`
  margin-top: 15px;
  margin-left: 15px;
`;

const MenuIconContainer = styled.div`
  background: #398089;
  width: 64px;
  height: 64px;
  border-radius: 100px;
  margin-bottom: 10px;
  margin-right: auto;
  margin-left: auto;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  color: white;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 15px;
  padding-top: 15px;
  text-align: center;
  width: 105px;
  &:hover {
    background-color: #4fa7b0;
  }
`;

const StyledIcon = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 15px;
  padding-top: 15px;
  text-align: center;
  width: 105px;
`;

export default MainNav;
