import React from 'react';
import _ from "lodash";
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import { BarGroup, BarStack } from '@visx/shape';
import { Grid, GridColumns } from '@visx/grid';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { schemeTableau10 } from 'd3-scale-chromatic';
import { LegendOrdinal } from '@visx/legend';
import { timeFormat, timeParse } from '@visx/vendor/d3-time-format';

export default class GroupStackBar extends React.Component {

    render() {
        const {
            data,
            width,
            height,
            margin = {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            fontSize = 12,
            hidden = true
        } = this.props;

        if(hidden) {
            return <div></div>
        }

        if (!Array.isArray(data)) {
            return <div>Not an array data</div>
        }

        // check if date is inside all data (filter out the one missing it)
        const filteredData = data.filter(d => timeParse('%Y-%m-%d')(d.date) !== null);
        if(!filteredData.length) {
            return <div>No valid data</div>
        }

        // add margin handling
        const yMax = height - margin.top  - margin.bottom;
        const xMax = width - margin.left - margin.right;

        if (yMax < 50 || xMax < 50) {
            return <div>Too small window for the chart</div>
        }

        // DATA SETUP
        const groupKeysSet = new Set(
            Object.keys(filteredData[0]).filter((d) => d !== 'date')
        );
        if(groupKeysSet.size > 4) {
            return <div>Too many group keys</div>
        }
        // check if group keys are consistent among all keys in filterData
        if(filteredData.some((d) => !_.isEqual(
            groupKeysSet,
            new Set(Object.keys(d).filter((d) => d !== 'date')))
        )) {
            return <div>Inconsistent group keys</div>
        }
        let stackKeysSet = new Set();
        filteredData.forEach((dt) => {
            groupKeysSet.forEach((k) => {
                Object.keys(dt[k]).forEach((k2) => stackKeysSet.add(k2));
            })
        })
        const groupKeys = [...groupKeysSet.values()];
        const stackKeys = [...stackKeysSet.values()].filter((d) => d !== '__max');
        if(stackKeys.length > 7) {
            return <div>Too many stack keys</div>
        }
        // check if all values are natural numbers
        if(filteredData.some((d) => groupKeys.some((k) => {
            if(d[k] !== undefined) {
                return Object.values(d[k]).some(v =>!Number.isInteger(v) || v < 0)
            } else {
                return false
            }
        }))) {
            return <div>Not all values are natural numbers</div>
        }
        filteredData.forEach((d) => {
            groupKeys.forEach((gk) => {
                d[gk]['__max'] = Object.keys(d[gk]).reduce(
                    (a, b) => b !== '__max' ? a + d[gk][b] : a,
                    0
                )
            })
        })
        const datesMaximums = filteredData.reduce(
            (allTotals, currentDate) => {
                allTotals.push(
                    Math.max(...groupKeys.map((k) => currentDate[k]['__max']))
                )
                return allTotals;
            },
            []
        );
        

        // GROUP SETUP
        const dateScale = scaleBand()
            .domain(filteredData.map((d) => d.date))
            .rangeRound([0, xMax]);
        const groupScale = scaleBand()
            .domain(groupKeys)
            .rangeRound([0, dateScale.bandwidth()])
            .padding(0.1);

        /// STACK SETUP
        const stackScale = scaleLinear()
            .domain([0, Math.max(...datesMaximums) * 1.1])
            .nice()
            .range([yMax, 0]);
        const stackIndexScale = scaleBand()
            .domain(Array.from(Array(groupKeys.length).keys()))
            .rangeRound([0, dateScale.bandwidth()])
            .padding(0.2);
        const stackColorScale = scaleOrdinal()
            .domain(stackKeys)
            .range(schemeTableau10);
        
        if(stackIndexScale.bandwidth() < 15) {
            return <div>Too small of stack size ({stackIndexScale.bandwidth()}px)</div>
        }

        const monthFormatter = timeFormat('%-d.%-m.%Y');

        return (<div style={{position: 'relative', width: width, height: height}}>
            <svg width={width} height={height}>
                <rect x={0} y={0} width={width} height={height} fill={'#ecf4f3'} rx={10}/>
                <AxisBottom
                    top={margin.top}
                    left={margin.left}
                    scale={dateScale}
                    hideAxisLine
                    ticksComponent={(props) => (
                        <Group>
                            {props.ticks.map((tick) => (
                                <rect
                                    key={tick.formattedValue}
                                    x={tick.from.x - dateScale.bandwidth() / 2}
                                    y={tick.from.y}
                                    height={yMax}
                                    width={dateScale.bandwidth()}
                                    fill={'white'}
                                    opacity={(tick.index % 2) === 0 ? 0.75 : 0.125}
                                />
                            ))}
                        </Group>
                    )}
                />
                <Grid
                    top={margin.top}
                    left={margin.left}
                    xScale={dateScale}
                    yScale={stackScale}
                    width={xMax}
                    height={yMax}
                    stroke="black"
                    strokeOpacity={0.1}
                    xOffset={dateScale.bandwidth() / 2}
                    />
                <GridColumns
                    top={margin.top}
                    left={margin.left}
                    scale={dateScale}
                    numTicks={filteredData.length}
                    width={xMax}
                    height={yMax + 25 + fontSize / 2}
                    stroke="black"
                    strokeOpacity={0.25}
                    offset={dateScale.bandwidth() / 2}
                    />
                <Group
                    top={margin.top} left={margin.left}
                >
                    <BarGroup
                        data={filteredData}
                        keys={groupKeys}
                        height={yMax}
                        x0={(d) => d.date}
                        x0Scale={dateScale}
                        x1Scale={groupScale}
                        yScale={scaleLinear()}
                        color={scaleOrdinal()}
                        >
                        {(barGroups) => barGroups.map((barGroup) => (
                            <Group key={`bar-group-${barGroup.index}`} left={barGroup.x0}>
                                {/*  At this spot, we have all the data necessary
                                for visualizaing single group - one Sunday.

                                However, BarStack would require the data in different
                                format in order to create stacked elements.

                                We transform data from:
                                {
                                    color
                                    height
                                    index
                                    key
                                    value: {positions: {...}}
                                    width
                                    x
                                    y
                                }
                                into:
                                {
                                    index,
                                    key,
                                    ...positions
                                }
                                for each Sunday.
                                */}
                                <BarStack
                                    data={barGroup.bars.map((bg, i) => ({
                                        index: i,
                                        key: bg.key,
                                        ...bg.value
                                    }))}
                                    keys={stackKeys}
                                    x={(d) => d.index}
                                    xScale={stackIndexScale}
                                    yScale={stackScale}
                                    color={stackColorScale}
                                >
                                    {(barStacks) => barStacks.map((barStack) => 
                                        barStack.bars.map((bar) => {
                                            if(Object.keys(bar.bar.data).indexOf(bar.key) !== -1) {
                                                return (<rect
                                                    key={`bar-stack-${barGroup.index}-${barStack.index}-${bar.index}`}
                                                    x={bar.x}
                                                    y={bar.y}
                                                    height={bar.height}
                                                    width={bar.width}
                                                    fill={bar.color}
                                                />)
                                            }
                                        })
                                    )}
                                </BarStack>
                                <Group>
                                    {barGroup.bars.map(barGroupBar => (
                                        <text
                                            key={`bar-group-bar-label-${barGroup.index}-${barGroupBar.key}`}
                                            x={barGroupBar.x + barGroupBar.width / 2}
                                            y={stackScale(barGroupBar.value['__max']) - 5}
                                            textAnchor={'middle'}
                                        >
                                            {barGroupBar.value['__max']}
                                        </text>
                                    ))}
                                </Group>
                            </Group>
                        ))}
                    </BarGroup>
                </Group>
                <AxisBottom
                    top={yMax + margin.top}
                    left={margin.left}
                    scale={dateScale}
                    hideAxisLine
                    hideTicks
                    tickLabelProps={{
                        fontSize: fontSize,
                    }}
                    tickFormat={(t, i) => `week ${i + 1}`}
                />
                <AxisBottom
                    top={yMax + margin.top + fontSize * 1.5}
                    left={margin.left}
                    scale={dateScale}
                    hideAxisLine
                    hideTicks
                    tickLabelProps={{
                        fontSize: fontSize,
                    }}
                    tickFormat={(t) => monthFormatter(new Date(t))}
                />
                <AxisLeft
                    top={margin.top}
                    left={margin.left}
                    scale={stackScale}
                    numTicks={5}
                    tickLabelProps={{
                        fontSize: fontSize
                    }}
                />
            </svg>
            <div
                style={{
                    position: 'absolute',
                    top: margin.top / 2 - 18,
                    left: margin.left,
                    width: '250',
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: `${fontSize}px`,
                    padding: '7.5px',
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    border: '1px solid #ccc'
                }}
            >
                <div style={{margin: '0 15px 0 0'}}>Legend:</div>
                <LegendOrdinal
                    scale={stackColorScale}
                    direction="row"
                    labelMargin="0 15px 0 0"
                />
            </div>
        </div>)
    }
}