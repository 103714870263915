import React from "react";
import PublicNav from "./public-nav";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";

const PublicSidebar = () => {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated)
    return <></>

  return (
    <Bar>
      <PublicNav />
    </Bar>
  );
};

const Bar = styled.div`
  display: flex;
  flex-direction: column;
  background: #26565B;
  position: absolute;
  top: -86px;
  padding-bottom: 86px;
  z-index: 1;
  min-height: 1000px;
  height: 100%;
`;

export default PublicSidebar;