import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import ReactCardFlip from 'react-card-flip';
import { Button } from "rendition";
import { formatStringDate } from "../lib/common";
import Icon from "../lib/icon";
import Tooltip from "../lib/tooltip";

const DepartmentCard = props => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <div style={{ display: "inline-block" }}>
      <Tooltip id={`flip-${props.department.id}`} />
      <ReactCardFlip isFlipped={isFlipped}>
        <DepartmentCardContainer>
          <Button
            style={{ position: "absolute", top: "25px", right: "25px" }} plain icon={<Icon name="information" />} onClick={() => setIsFlipped(true)}
            data-for={`flip-${props.department.id}`}
            data-tip="På baksiden av avdelings kortet finner du avdelings-ID Hvis du kontakter support er det fint om du kopierer ID-nummer så vet vi hvilken avdeling du jobber med" />
          <NavLink className="nav-link" to={{ pathname: `/avdelinger/${props.department.id}`, state: { departmentName: props.department.name } }}>
            <DepartmentCardFront>
              <DepartmentCardLogo>
                <Icon name="department" />
              </DepartmentCardLogo>
              <span>{props.department.name}</span>
            </DepartmentCardFront>
            <DepartmentFooter>
              <p>Gå til avdeling</p>
            </DepartmentFooter>
          </NavLink>
        </DepartmentCardContainer>

        <DepartmentCardContainer>
          <Button style={{ position: "absolute", top: "25px", right: "25px" }} plain icon={<Icon name="remove" />} onClick={() => setIsFlipped(false)} />
          <DepartmentCardBack>
            <p><b>{props.department.name}</b></p>
            <p>Opprettet: {formatStringDate(props.department.registered)}</p>
          </DepartmentCardBack>
          <DepartmentFooter>
            <p>ID: {props.department.id}</p>
          </DepartmentFooter>
        </DepartmentCardContainer>
      </ReactCardFlip>
    </div>
  );
};

const DepartmentCardContainer = styled.div`
  margin: 10px;
  width: 367px;
  min-height: 225px;
  max-height: 225px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  background-color: #D9ECE6;
`

const DepartmentCardFront = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  width: 200px;
  word-break: break-all;
`

const DepartmentCardBack = styled.div`
  top: 5px;
  left: 20px;
  position: relative;
  font-size: 14px;
  width: 300px;
`

const DepartmentCardLogo = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 15px;
`

const DepartmentFooter = styled.div`
  background-color: #26565BD1;
  position: absolute;
  top: 180px;
  height: 50px;
  width: 367px;
  color: white;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  border-radius: 0px 0px 10px 10px;
`

export default DepartmentCard;
