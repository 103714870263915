import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Tooltip from "../lib/tooltip";
import { dynoapeAPI } from "../api/dynoape";

const Header = ({
  style,
  withTooltip,
  tooltipId,
  tooltipContent,
  title,
  subTitle,
  children,
  showLicenseEmployeeInfo,
  showLicenseGenerationsInfo,
  refresh = 0,
}) => {
  const [departmentName, setDepartmentName] = useState("");
  const [license, setLicense] = useState();

  const [loading, setLoading] = useState(true);
  const departmentId = location.pathname
    .split("/avdelinger/")[1]
    ?.split("/")[0];

  useEffect(() => {
    const fetchData = async () => {
      if (departmentId) {
        const department = await dynoapeAPI.get(
          `/api/v1/department/${departmentId}`
        );
        if (department) setDepartmentName(department.name);
        setLoading(false);
      } else {
        setDepartmentName("");
        setLoading(false);
      }
      if (showLicenseGenerationsInfo || showLicenseEmployeeInfo) {
        setLicense(
          await dynoapeAPI.get(
            `/api/v1/department/${departmentId}/active-license`
          )
        );
      }
    };
    fetchData();
  }, [departmentId, departmentName, refresh]);

  return (
    <TopBar>
      {!loading && (
        <>
          <MiddleAlign style={style}>
            {withTooltip && (
              <HeaderTitle
                data-for={tooltipId}
                data-tip={tooltipContent}
                data-multiline={true}
                data-place="bottom"
              >
                {title}
                <Tooltip id={tooltipId} />
              </HeaderTitle>
            )}
            {!withTooltip && <HeaderTitle>{title}</HeaderTitle>}
            {children}
          </MiddleAlign>
          <HeaderSubTitle>
            {
              <HeaderSubTitleEl>
                {" "}
                {subTitle ? subTitle : departmentName}{" "}
              </HeaderSubTitleEl>
            }{" "}
            {license && (
              <>
                {showLicenseEmployeeInfo && (
                  <HeaderSubTitleEl>
                    Ansatte: {license.employeesInDepartment} (
                    {license.remainingEmployees} gjenværende)
                  </HeaderSubTitleEl>
                )}
                {/* TODO  */}
                {showLicenseGenerationsInfo && (
                  <HeaderSubTitleEl>
                    Genereringer: {license.generationsInPeriod} (
                    {license.remainingGenerations} gjenværende)
                  </HeaderSubTitleEl>
                )}
              </>
            )}
          </HeaderSubTitle>
        </>
      )}
    </TopBar>
  );
};

const TopBar = styled.div`
  flex-direction: row;
  align-items: center;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    #67b59d;
  height: 160px;
  padding: 20px 250px 20px 250px;
  position: relative;
`;

const MiddleAlign = styled.div`
  display: flex;
  padding: 10px 0 0 0;
  position: relative;
`;

const HeaderTitle = styled.p`
  font-family: Montserrat Alternates;
  font-size: 33px;
  font-weight: 700;
  margin: 0px 10px 0px 10px;
  color: #26565b;
`;

const HeaderSubTitle = styled(HeaderTitle)`
  font-family: Roboto;
  font-size: 16px;
`;

const HeaderSubTitleEl = styled.div`
  float: left;
  margin-right: 200px;
`;

export default Header;
