import React, { useState, useEffect } from "react";
import { Checkbox } from "rendition";

const CombinedCheckbox = ({ label, name, value, onChange, defaultChecked, toggle, register, disabled=false }) => {
  const [checked, setChecked] = useState(defaultChecked || false);

  useEffect(() => {
    if (onChange) {
      onChange(checked);
    }
  }, [checked]);

  return (
    <Checkbox
      label={label}
      backgroundColor={!disabled ? "": "WhiteSmoke" }
      name={name}
      value={value}
      checked={checked}
      toggle={toggle}
      register={register}
      disabled={disabled}
      onChange={() => setChecked(!checked)}
    />
  );
};

export { CombinedCheckbox };