import React from "react";
import styled from 'styled-components';

const HomeContent = () => (
  <div style={{
    backgroundImage: `url("/assets/images/bg.jpg")`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: 'calc(100vh - 86px)', position: "relative"
  }}>
    <div>
      <Title>Optimalisert turnusplanlegging</Title>
    </div>
  </div>
);

const Title = styled.div`
  font-family: Montserrat Alternates;
  font-style: normal;
  font-weight: bold;
  font-size: 52px;
  align-items: center;
  text-align: center;
  margin: auto;
  color: #FFFFFF;
  padding-top: 200px;
`;

export default HomeContent;