import React, { useEffect, useState } from "react";
import { Header, ResultTable } from "../components";
import { BackButton } from "../components/buttons";
import { dynoapeAPI } from "../api/dynoape";
import { useParams } from "react-router-dom";

const Results = () => {
  const [task, setTask] = useState();
  const { departmentId, taskId } = useParams();

  const getTask = async () => {
    const response = await dynoapeAPI.get(`/api/v1/department/${departmentId}/task/${taskId}`);

    setTask(response);
  };

  useEffect(() => {
    getTask();
  }, []);

  return (
    <div>
      <Header title={task ? `Resultater ${task.name}` : 'Resultater'}
        withTooltip
        tooltipId="results-header"
        tooltipContent="Her finner du en oversikt over de 10 beste turnusene som Dynamon har generert. <br />
        Dersom generatoren ikke er ferdig å generere, vil du til enhver tid se de 10 beste turnusene som er generert til nå. <br />
        Du kan laste ned en turnus og se på denne, eller vente til generatoren er ferdig å generere turnus før du laster ned turnusen">
        <BackButton />
      </Header>
      <div className="content">
        <div>
          <div
            style={{
              display: "flex",
            }}
          >
            <h3 style={{ marginLeft: "10px" }}>Liste over resultater</h3>
          </div>
          <ResultTable task={task} />
        </div>
      </div>
    </div>
  );
};

export default Results;
