import React from "react";
import PositionsTable from "./positions-table";
import CompetencesTable from "./competences-table";
import Tooltip from "../lib/tooltip";
import { SettingsContainer } from "../lib/styled-components";

const DepartmentSettings = () => {
  return (
    <SettingsContainer>
      <h3 style={{ width: "80px" }}
        data-for="department-positions"
        data-tip="Her kan du legge til inntil 5 stillingstitler for din avdeling"
      >Stillingskategorier</h3>
      <Tooltip id="department-positions" />
      <PositionsTable />
      <h3 style={{ width: "210px" }}
        data-for="department-competences"
        data-tip="Her kan du legge til spesialkompetanse for din avdeling"
      >Spesialkompetanse</h3>
      <Tooltip id="department-competences" />
      <CompetencesTable />
    </SettingsContainer>
  );
};

export default DepartmentSettings;