import React from "react";
import { Route } from "react-router-dom";

let TestView;
if (process.env.NODE_ENV !== 'production') {
  TestView = require('./testView').default;
} else {
  TestView = () => null;
}

const TestRoute = ({...rest}) => {
  if (process.env.NODE_ENV === "development") {
    return <Route component={TestView} {...rest} />;
  } else {
    return null;
  }
};

export default TestRoute;